import { last, map, takeRightWhile } from "lodash";
import { useCallback, useMemo } from "react";
import { Route, useLocation } from "react-router-dom";

import { Entity, EntityType, ID } from "@api";

import { useAllowedChildren } from "@state/properties";
import { usePackageIcon } from "@state/packages";
import { useEntityLabels } from "@state/settings";
import {
  toTemplateViewId,
  useAllViewsForParent,
  useLazyViewsForParent,
} from "@state/views";

import { Fn } from "@utils/fn";
import { maybeTypeFromId } from "@utils/id";
import { Maybe, when } from "@utils/maybe";
import { takeUpTo } from "@utils/scope";

import { Home } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { RouteMenuItem } from "@ui/menu-item";

type Route = {
  pathname: string;
  type: Maybe<EntityType>;
  id: Maybe<ID>;
};
// TODO: Should be converted into a component similar to Routes
export const useSubRouter = (id: ID): Route => {
  const location = useLocation();
  return useMemo(() => {
    const parts = takeRightWhile(location.pathname.split("/"), (p) => p !== id);
    const lastPart = last(parts);
    const lastType = when(lastPart, maybeTypeFromId<EntityType>);
    return {
      pathname: parts?.join("/") || "/",
      type: lastType,
      id: lastType ? lastPart : undefined,
    };
  }, [location?.pathname, id]);
};

export const SubRouter = ({
  id,
  router,
}: {
  id: ID;
  router: Fn<Route, JSX.Element>;
}) => {
  const subroute = useSubRouter(id);
  return useMemo(() => router(subroute), [subroute]);
};

export const SubRoutesMenu = ({ entity }: { entity: Entity }) => {
  const location = useLocation();

  const childTypes = useAllowedChildren(entity, [
    "resource",
    "note",
    "view",
    "schedule",
  ]);
  const basePath = useMemo(
    () => takeUpTo(location.pathname, entity.id, true),
    [location.pathname, entity.id]
  );
  // Keeps current base bath and appends the subroute
  const toChildRoute = useCallback(
    (sub?: string) => when(sub, (s) => `${basePath}/${s}`) || basePath,
    [basePath]
  );

  return (
    <Menu>
      <MenuGroup>
        <RouteMenuItem text="Overview" icon={Home} route={toChildRoute()} />

        {map(childTypes, (type) => (
          <SubRouteMenuItem
            key={type}
            entity={entity}
            type={type}
            toRoute={toChildRoute}
          />
        ))}
      </MenuGroup>
    </Menu>
  );
};

const SubRouteMenuItem = ({
  entity,
  type,
  toRoute,
}: {
  type: EntityType;
  entity: Entity;
  toRoute: Fn<string, string>;
}) => {
  const toLabel = useEntityLabels(entity.source.scope);
  const toIcon = usePackageIcon();
  const defaultViews = useLazyViewsForParent(entity.id, type, false);

  return (
    <RouteMenuItem
      key={type}
      icon={toIcon(type)}
      route={toRoute(defaultViews[0]?.id)}
    >
      {toLabel(type, { plural: true })}
    </RouteMenuItem>
  );
};
