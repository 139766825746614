import { useMemo } from "react";

import { BaseColor } from "@api";

import { fromColor, toColorVar } from "@utils/color";
import { switchEnum } from "@utils/logic";
import { when } from "@utils/maybe";

import { ThemeState } from "@ui/theme";

export function GlassBackground({
  color: themeColor,
  mode: themeMode,
  children,
}: Partial<ThemeState> & { children: React.ReactNode }) {
  const [base, shade] = useMemo(
    () => (!themeColor ? [undefined, undefined] : fromColor(themeColor)),
    [themeColor]
  );

  const colorVar = useMemo(
    () => when(base, (base) => toColorVar(base as BaseColor, shade)),
    [base, shade]
  );
  const [fadeFrom, fadeTo] = useMemo(
    () =>
      switchEnum(themeMode || "light", {
        dark: [0.1, 0.4],
        light: shade === 5 ? [0.05, 0.1] : [0.2, 0.4],
      }),
    [themeMode, shade]
  );

  return (
    <div
      data-background={true}
      style={useMemo(() => {
        const background = "var(--color-background)";
        return {
          backgroundImage:
            colorVar &&
            `
            linear-gradient(90deg, 
              color-mix(in srgb, ${colorVar} ${
              fadeFrom * 100
            }%, ${background}), 
              color-mix(in srgb, ${colorVar} ${fadeTo * 100}%, ${background})
            )
          `,
          backgroundColor: background,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          heading: "100%",
        };
      }, [themeColor, themeMode, colorVar, fadeFrom, fadeTo])}
    >
      {children}
    </div>
  );
}
