import { isString } from "lodash";
import { useMemo } from "react";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useMe } from "@state/persons";
import {
  isTemplateViewId,
  toTemplateViewId,
  useGoToView,
  useLazyGetView,
} from "@state/views";
import { useAuthedUserId } from "@state/workspace";

import { useStickyState } from "@utils/hooks";
import { isLocalID } from "@utils/id";
import { usePathName } from "@utils/navigation";
import { useSyncPathnameID } from "@utils/url";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { StackContainer } from "@ui/sheet-layout";
import ViewPane from "@ui/view-pane";

import AppPage from "./app-page";

interface Props {
  viewId: string;
  showBack?: boolean;
}

export const ViewPage = ({ viewId, showBack: _showBack }: Props) => {
  const me = useMe();
  const view = useLazyGetView(viewId);
  const pathName = usePathName();
  const [page] = useRegisterPage(viewId, view);
  const onOpen = useGoToView();
  const showBack = useMemo(
    () =>
      // Hide back bar if we are on a /u_*/v_* path for ourselves
      _showBack ??
      !(view?.location === me.id && pathName?.startsWith(`/${me.id}`)),
    [_showBack, pathName]
  );

  const showOtherViews = useMemo(
    () =>
      // Not privave
      view?.location !== me.id &&
      // Not nested-pages tempalte (lol massive hack)
      !(view?.alias || view?.id)?.includes("nested-pages"),
    [view?.location, me.id]
  );

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(viewId, view?.id);

  if (!view) {
    return <>View not found for {viewId}</>;
  }

  return (
    <AppPage page={page} loading={!view} title={view?.name}>
      <StackContainer>
        {!!showBack && <SmartBreadcrumbSheet />}

        <ViewPane
          viewId={viewId}
          onChangeView={onOpen}
          showOtherViews={showOtherViews}
        />
      </StackContainer>
    </AppPage>
  );
};

export const ViewOnlyPage = ({ viewId }: { viewId: string }) => {
  return <ViewPage viewId={viewId} />;
};

export const ProjectViewPage = ({
  viewId: _viewId,
  ...props
}: Partial<Props> & { projectId: string }) => {
  const [defaultViewId] = useStickyState(
    toTemplateViewId("project-task", {
      parent: props.projectId,
    }),
    `view-${props.projectId}-default`,
    (id) =>
      !!id && isString(id) && !isLocalID(id) && !isTemplateViewId(id)
        ? id
        : undefined
  );

  return <ViewPage {...props} viewId={_viewId || defaultViewId || ""} />;
};

export const PersonalViewPage = ({
  viewId: _viewId,
  ...props
}: Partial<Exclude<Props, "projectId">>) => {
  const activeId = useAuthedUserId();
  const [defaultViewId] = useStickyState(
    toTemplateViewId("person-task", { parent: activeId }),
    `view-${activeId}-default`,
    (id) =>
      !!id && isString(id) && !isLocalID(id) && !isTemplateViewId(id)
        ? id
        : undefined
  );

  return (
    <ViewPage {...props} viewId={_viewId || defaultViewId} showBack={false} />
  );
};

export default ViewPage;
