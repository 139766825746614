import { ReactNode, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import { useActivePage, useAppCommandsAction } from "@state/app";
import { useUnsavedUpdates } from "@state/generic";
import { JobStoreAtom } from "@state/jobs";
import { WorkflowRunContextAtom } from "@state/workflow";

import { cx } from "@utils/class-names";
import { safeAs, when } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";

import { Button, Props as ButtonProps } from "@ui/button";
import { Dropdown } from "@ui/dropdown";
import { WorkflowDetailsPopup } from "@ui/engine/workflow";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon, SpinnerIcon } from "@ui/icon";

import styles from "./app-footer-bar.module.css";

export const AppFooterBar = () => {
  const savingQueue = useUnsavedUpdates();
  const page = useActivePage();
  const store = useRecoilValue(JobStoreAtom);
  const [workflowId, setWorkflowId] = useState<string>();
  const lingeringLast = useRef<NodeJS.Timeout>();
  const open = useAppCommandsAction();

  const runningWorkflow = useRecoilValue(
    WorkflowRunContextAtom(workflowId || "")
  );

  // When running workflow changes to another valid workflow, immediately change
  // when it changes to nothing, show the last workflow for a while
  useEffect(() => {
    const id =
      safeAs<string>((store.claiming || store.running)?.data?.id) || "";
    if (id) {
      setWorkflowId(id);
      when(lingeringLast.current, clearTimeout);
      lingeringLast.current = undefined;
    } else if (!id && workflowId) {
      lingeringLast.current = setTimeout(() => setWorkflowId(undefined), 10000);
    }
  });

  return (
    <SpaceBetween align="center">
      <span></span>

      <HStack justify="flex-end">
        {runningWorkflow ? (
          <FooterButton
            key="workflow"
            icon={<Icon size="xsmall" icon={SpinnerIcon} />}
            defaultOpen={
              !runningWorkflow.workflow.options?.background &&
              page?.entity?.id !== runningWorkflow?.workflow?.id
            }
            details={
              <WorkflowDetailsPopup workflowId={runningWorkflow?.workflow.id} />
            }
          >
            Running workflow {runningWorkflow?.workflow?.name}
          </FooterButton>
        ) : (
          when(store.claiming || store.running, (job) => (
            <FooterButton key="job" details={<></>}>
              Running background job
            </FooterButton>
          ))
        )}

        <FooterButton
          key="saving"
          className={styles.saving}
          onClick={() => open("debug")}
        >
          {savingQueue?.length ? "Saving changes" : "Changes saved"}
        </FooterButton>
      </HStack>
    </SpaceBetween>
  );
};

const FooterButton = ({
  icon,
  defaultOpen,
  children,
  details,
  className,
  ...rest
}: {
  children: ReactNode;
  details?: ReactNode;
  defaultOpen?: boolean;
} & ButtonProps) => {
  const [open, setOpen] = useState(defaultOpen ?? false);

  const button = (
    <Button
      icon={icon}
      subtle
      wrapLabel={false}
      size="small"
      className={cx(styles.footerButton, className)}
      {...rest}
    >
      {children}
    </Button>
  );

  return !details ? (
    button
  ) : (
    <Dropdown
      open={open}
      setOpen={setOpen}
      position="bottom-right"
      trigger={button}
    >
      {details}
    </Dropdown>
  );
};
