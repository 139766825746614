import { first, map, some, values } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { Entity, ID } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity, useLazyQuery } from "@state/generic";
import {
  SystemPackages,
  useAnyHasPackages,
  usePackageInstaller,
} from "@state/packages";
import { useMe } from "@state/persons";
import { ensureSpace, useActiveSpace, useSpaceState } from "@state/spaces";
import { toTemplateViewId } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { Fn } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { isPersonId, isViewId, maybeTypeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { useGoTo, usePushTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { Centered, HStack, SpaceBetween, VStack } from "@ui/flex";
import { PackagesMarketplace } from "@ui/packages-markplace";
import { Main, PageLayout, TopBar } from "@ui/page-layout";
import { ParentCard } from "@ui/parent-card";
import { Sheet, WrapContainer } from "@ui/sheet-layout";
import { SpaceFilterSelect } from "@ui/space-filter-select";
import { Heading, Text, TextSmall, TextXLarge } from "@ui/text";
import { TabbedViewCard } from "@ui/view-card";
import { ViewCardCompact } from "@ui/view-card-compact";

import AppPage from "./app-page";

import styles from "./home-page.module.css";

interface Props {}

const HomePage = ({}: Props) => {
  const me = useMe();
  const wId = useActiveWorkspaceId();
  const space = useActiveSpace();
  const [page] = useRegisterPage("home", undefined);
  const [showAll, setShowAll] = useStickyState<boolean>(
    () => true,
    `home-show-all`
  );

  usePageUndoRedo(page.id);

  // Ensure current user is always fetched on first load
  useLazyEntity(me.id, false);

  // Switch to personal space if not already in
  ensureSpace(me?.id);

  if (!me) {
    return <SpaceSetup />;
  }

  return (
    <AppPage page={page} title="Home">
      <PageLayout bar="top">
        <TopBar>
          <Container stack={"horizontal"} inset="bottom">
            <SpaceBetween>
              <Heading bold>Home</Heading>
              <HStack gap={4}>
                <Text subtle>Showing work from </Text>
                <SpaceFilterSelect
                  value={showAll ? wId : space.id}
                  onChanged={(id) => setShowAll(id === wId)}
                  caret={true}
                  searchable={false}
                  casing="lower"
                />
              </HStack>
            </SpaceBetween>
          </Container>
        </TopBar>

        <Main>
          <HomePanes filter={wId} />
        </Main>
      </PageLayout>
    </AppPage>
  );
};

interface HomePanesProps {
  filter: ID;
}

const HomePanes = ({ filter }: HomePanesProps) => {
  const workspaceId = useActiveWorkspaceId();
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const me = useMe();

  const toCheck = useMemo(
    () =>
      switchEnum(maybeTypeFromId(filter) || "", {
        // Show all teams
        workspace: () => [workspaceId, ...map(me.teams || [], (t) => t.id)],
        // Show all teams when in "my space"
        person: () => [workspaceId, ...map(me.teams || [], (t) => t.id)],
        // Individual team, inheriting from workspace
        team: () => [workspaceId, filter],
        // Otherwise workspace -_-
        else: () => [workspaceId],
      }),
    [workspaceId, me.teams, me.id, filter]
  );
  const installed = useAnyHasPackages(toCheck, [
    SystemPackages.Meetings,
    SystemPackages.Outcomes,
    SystemPackages.Pages,
    SystemPackages.Content,
    SystemPackages.Events,
    SystemPackages.Deals,
    SystemPackages.Campaigns,
    SystemPackages.Sprints,
    SystemPackages.Tasks,
    SystemPackages.Workflows,
    SystemPackages.Projects,
  ]);

  const viewParams = useMemo(
    () =>
      isPersonId(filter)
        ? // Private spaces show all data in the space (doesn't have to be assigned to yourself)
          { parent: me.id, for: filter }
        : // Workspace|Team spaces filter to my work by default
          { parent: me.id, for: filter, owner: me.id },
    [me.id, filter]
  );

  const toId = useCallback(
    (template: string) => toTemplateViewId(template, viewParams),
    [viewParams]
  );

  const taskViewIds = useMemo(
    () => [toId("my-tasks"), toId("my-recently-completed"), toId("my-planned")],
    [toId]
  );

  const noneInstalled = useMemo(() => {
    return !some(values(installed), Boolean);
  }, [installed]);

  if (noneInstalled && !!isPersonId(filter)) {
    return <SpaceSetup />;
  }

  return (
    <WrapContainer>
      <SpaceBetween fit="container" align="stretch" gap={16}>
        <Sheet size="primary" mode="sizing" height="content">
          <VStack gap={10}>
            {!!installed[SystemPackages.Tasks] && (
              <TabbedViewCard
                viewIds={taskViewIds}
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Meetings] && (
              <ViewCardCompact
                viewId={toId("my-actions")}
                onOpen={pushTo}
                empty="collapse"
                size="full"
              />
            )}

            {!!installed[SystemPackages.Deals] && (
              <ViewCardCompact
                viewId={toId("my-deals")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Content] && (
              <ViewCardCompact
                viewId={toId("my-contents")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Events] && (
              <ViewCardCompact
                viewId={toId("my-events")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}
          </VStack>
        </Sheet>
        <Sheet size="secondary" mode="sizing" height="content">
          <VStack fit="container">
            {installed[SystemPackages.Sprints] && (
              <CurrentSprintCard onClick={pushTo} />
            )}

            {isPersonId(filter) && !!installed[SystemPackages.Pages] && (
              <>
                <ViewCardCompact
                  viewId={toId("my-pages")}
                  onOpen={pushTo}
                  empty="show"
                  editable={true}
                  size="full"
                />
              </>
            )}

            {!!installed[SystemPackages.Meetings] && (
              <>
                <ViewCardCompact
                  viewId={toId("my-today-meetings")}
                  onOpen={(ref) =>
                    isViewId(ref.id) ? goTo("meetings") : pushTo(ref)
                  }
                  empty="collapse"
                  size="full"
                />
              </>
            )}

            {!!installed[SystemPackages.Campaigns] && (
              <ViewCardCompact
                viewId={toId("my-campaigns")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Projects] && (
              <ViewCardCompact
                viewId={toId("my-projects")}
                empty="hide"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Outcomes] && (
              <ViewCardCompact
                viewId={toId("my-outcomes")}
                empty="collapse"
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            {!!installed[SystemPackages.Workflows] && (
              <ViewCardCompact
                viewId={toId("my-workflows")}
                onOpen={pushTo}
                empty="collapse"
                size="full"
              />
            )}
          </VStack>
        </Sheet>
      </SpaceBetween>
    </WrapContainer>
  );
};

const CurrentSprintCard = ({ onClick }: { onClick: Fn<Entity, void> }) => {
  const sprints = useLazyQuery("sprint", {
    field: "status",
    type: "status",
    op: "equals",
    value: { status: { group: "in-progress" } },
  });
  const sprint = useMemo(() => first(sprints), [sprints]);

  if (!sprint) {
    return <></>;
  }

  return (
    <ParentCard
      id={sprint.id}
      className={styles.sprintcard}
      label="Current"
      onClick={onClick}
    />
  );
};

export default HomePage;

const SpaceSetup = () => {
  const space = useActiveSpace();
  const [showingMarketplace, setShowingMarketplace] = useState(false);
  const { install, loading } = usePackageInstaller(space.id);
  const label = useMemo(
    () =>
      switchEnum(space.mode || "", {
        person: "Setup your private space",
        team: "Setup your team space",
        else: () => "Setup your space",
      }),
    [space.mode]
  );

  const handleInstallBasics = useCallback(() => {
    install(SystemPackages.Tasks);
    install(SystemPackages.Pages);
  }, [install]);

  return (
    <Container>
      {showingMarketplace && (
        <PackagesMarketplace
          scope={space.id}
          onClose={() => setShowingMarketplace(false)}
        />
      )}

      <Centered>
        <Card fit="content" className={styles.quickSetup}>
          <VStack gap={20}>
            <VStack gap={0}>
              <TextXLarge bold>{label}</TextXLarge>
              <Text subtle>
                Just like any team, you can install any packages in your private
                space.
              </Text>
            </VStack>

            <SpaceBetween direction="horizontal" className={styles.centered}>
              <Container stack="vertical" width="container" align="center">
                <VStack gap={0} align="center">
                  <Text bold>Install the Basics</Text>
                  <TextSmall subtle>
                    Start with a few common packages.
                  </TextSmall>
                </VStack>
                <Button
                  fit="content"
                  subtle
                  variant="primary"
                  onClick={handleInstallBasics}
                >
                  Install
                </Button>
              </Container>

              <Divider direction="vertical" className={styles.vertDivider} />

              <Container stack="vertical" width="container" align="center">
                <VStack gap={0} align="center">
                  <Text bold>Open Marketplace</Text>
                  <TextSmall subtle>
                    Install just exactly what you need.
                  </TextSmall>
                </VStack>
                <Button
                  fit="content"
                  onClick={() => setShowingMarketplace(true)}
                >
                  Open
                </Button>
              </Container>
            </SpaceBetween>
          </VStack>
        </Card>
      </Centered>
    </Container>
  );
};
