import { sentenceCase } from "change-case";
import { differenceInMonths, differenceInWeeks, isToday } from "date-fns";
import {
  every,
  find,
  flatten,
  isObject,
  last,
  map,
  omit,
  orderBy,
  reduce,
  without,
} from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Entity,
  EntityType,
  FilterQuery,
  HasAssigned,
  HasCreatedBy,
  HasOwner,
  HasRefs,
  HasTimestamps,
  ID,
  isNote,
  Note,
  Person,
  Ref,
  toTitleOrName,
} from "@api";
import { EntityMap } from "@api/mappings";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyFetchResults } from "@state/fetch-results";
import { useQueueUpdates } from "@state/generic";
import { useInstalledEntities } from "@state/packages";
import { toDisplayName, useHasSeen, useMe } from "@state/persons";
import { useActiveSpace } from "@state/spaces";
import { useActiveWorkspaceId } from "@state/workspace";

import { InnerType, omitEmpty, pushDirty } from "@utils/array";
import { equals, formatShort, formatTime } from "@utils/date";
import { fromPointDate } from "@utils/date-fp";
import { latest, toJSDate } from "@utils/epoch-date";
import { fallback, Fn, use } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { isWorkspaceId, typeFromId } from "@utils/id";
import { toISODate } from "@utils/iso-date";
import { equalsAny, ifDo, switchEnum } from "@utils/logic";
import { toPlainText } from "@utils/markdown";
import { Maybe, maybeMap, safeAs, SafeRecord, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { now } from "@utils/now";
import { maybeValues } from "@utils/object";
import { isSeen } from "@utils/person";
import { asAppendMutation, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { toMarkdown } from "@utils/rich-text";
import { fromScope } from "@utils/scope";
import {
  clearSelection,
  IgnoreSelectable,
  isSelected,
  SelectionState,
  SetSelectionState,
  usePageSelection,
} from "@utils/selectable";
import { toArray } from "@utils/set";

import { ActionItem, ActionMenu } from "@ui/action-menu";
import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Divider } from "@ui/divider";
import { EmptyState } from "@ui/empty-state";
import { render, useEngine } from "@ui/engine";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowUpRight,
  EmojiIcon,
  Eye,
  EyeSlash,
  FilterAlt,
  Icon,
  MarkAsSeen,
  UnreadIndicator,
} from "@ui/icon";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { RelationIcon } from "@ui/relation-label";
import { RelationTooltip } from "@ui/relation-tooltip";
import { SelectableListItem } from "@ui/selectable-items";
import { Sheet, WrapContainer } from "@ui/sheet-layout";
import { SpaceFilterSelect } from "@ui/space-filter-select";
import { Switch } from "@ui/switch";
import { TabBar, TabItem } from "@ui/tab-bar";
import { Tag } from "@ui/tag";
import { Heading, Text, TextLarge, TextSmall } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import AppPage from "./app-page";

import styles from "./inbox-page.module.css";

interface Props {}

interface InboxItem {
  id: ID;
  type:
    | "created"
    | "assigned"
    | "progressed"
    | "messaged"
    | "assigned"
    | "progressed"
    | "messaged";
  title: string;
  subtitle: string;
  entity: Entity;
  openTarget?: ID;
  seen?: boolean;
}

type InboxSettings = {
  show: "all" | "note";
  scope: "all" | "space";
  hideSeen?: boolean;
  filter: ("assigned" | "following" | "created" | "mentioned")[];
};

const TAB_BAR_ITEMS: TabItem[] = [
  { id: "all", title: "All Work" },
  { id: "note", title: "Messages" },
];

const filterSupported = (
  show: InboxSettings["show"],
  filter: InboxSettings["filter"]
) =>
  show === "all"
    ? equalsAny(filter[0], ["assigned", "following", "created"])
    : equalsAny(filter[0], ["following", "mentioned", "created"]);

const toDay = (entity: Entity) =>
  when(
    safeAs<HasTimestamps>(entity),
    (e) =>
      when(
        latest(
          e.stamps?.status?.at,
          e.stamps?.notes?.at,
          e.stamps?.assigned?.at
        ),
        (d) => toISODate(toJSDate(d), "utc")
      ) || e.createdAt
  ) || entity.createdAt;

const toGroup = (date: Date) => {
  const tod = isToday(date);
  if (tod) {
    return "Today";
  }
  const weeks = differenceInWeeks(date, now());
  if (weeks <= 1) {
    return weeks === 0 ? "This week" : "Last week";
  }
  const months = differenceInMonths(date, now());
  if (months <= 1) {
    return months === 0 ? "This month" : "Last month";
  }
  return "Earlier";
};

const toInboxItem = (entity: Entity, me: Person): InboxItem => {
  const latest = fromPointDate(toDay(entity));
  const type: InboxItem["type"] = equals(
    latest,
    when(safeAs<HasTimestamps>(entity)?.stamps?.assigned?.at, toJSDate)
  )
    ? "assigned"
    : equals(
        latest,
        when(safeAs<HasTimestamps>(entity)?.stamps?.status?.at, toJSDate)
      )
    ? "progressed"
    : equals(
        latest,
        when(safeAs<HasTimestamps>(entity)?.stamps?.notes?.at, toJSDate)
      )
    ? "messaged"
    : "created";

  return switchEnum(entity.source.type, {
    note: () =>
      use(
        when(safeAs<Note>(entity)?.body, (rt) => toPlainText(toMarkdown(rt))),
        (message) => ({
          id: entity.id,
          type,
          title: message?.includes(toDisplayName(me))
            ? "Mentioned you in a message"
            : "Wrote a message",
          subtitle: message || "Click to read.",
          entity,
          seen: isSeen(entity, me),
          openTarget: last(fromScope(entity.source.scope)),
        })
      ),
    else: () => ({
      id: entity.id,
      type,
      title: switchEnum(type, {
        assigned: () => "Assigned some work to you",
        progressed: () => "Changed the status",
        messaged: () => "Wrote a message",
        else: () => `Created some work`,
      }),
      subtitle: toTitleOrName(entity),
      entity,
      seen: isSeen(entity, me),
      openTarget: entity.id,
    }),
  });
};

const InboxPage = ({}: Props) => {
  const [page] = useRegisterPage("home", undefined);
  usePageUndoRedo(page.id);
  return (
    <AppPage page={page} title="Home">
      <WrapContainer className={styles.fitHeight}>
        <InboxPane />
      </WrapContainer>
    </AppPage>
  );
};

const InboxPane = ({}: Props) => {
  const pageId = usePageId();
  const me = useMe();
  const pushTo = usePushTo();
  const wId = useActiveWorkspaceId();
  const _entities = useInstalledEntities(me.id);
  const space = useActiveSpace();
  const entities = useMemo(
    () => without(_entities, "team", "workspace", "resource"),
    [_entities]
  );
  const [selection, setSelection] = usePageSelection();
  const [resultsByType, setResultsByType] = useState<EntityMap>({});
  const [settings, setSettings] = useStickyState<InboxSettings>(
    {
      show: "all",
      filter: ["following"],
      hideSeen: true,
      scope: "all",
    },
    "inbox-2-settings",
    (stored) => {
      if (isObject(stored)) {
        return stored as InboxSettings;
      }
      return undefined;
    }
  );
  const mutate = useQueueUpdates(pageId);
  const scope = useMemo(
    () =>
      settings.scope === "all" || space.mode === "person" ? wId : space.id,
    [settings.scope, wId, space.id]
  );
  const activeId = useMemo(() => selection.focused, [selection]);
  const activeType = useMemo(
    () => when(activeId, (id) => typeFromId<EntityType>(id)),
    [activeId]
  );
  const engine = useEngine(activeType);

  const { all, grouped, indexed } = useMemo(() => {
    const indexed: SafeRecord<ID, InboxItem> = {};
    const byGroup = reduce(
      flatten(
        maybeValues(
          resultsByType as Record<EntityType, Entity[]>,
          (_ts, type) => settings.show === "all" || type === "note"
        ) as Entity[][]
      ),
      (acc, entity) => {
        const item = toInboxItem(entity, me);

        indexed[item.id] = item;

        // Skip if hiding seen and already seen
        if (
          settings.hideSeen &&
          item.seen &&
          !selection.selected.has(entity.id) // Prevent selection dissapearing
        ) {
          return acc;
        }

        const key = toGroup(fromPointDate(toDay(entity)));
        acc[key] = pushDirty(acc[key] || [], item);
        return acc;
      },
      {} as SafeRecord<string, InboxItem[]>
    );

    const all: InboxItem[] = [];
    const days = [
      "Today",
      "This week",
      "Last week",
      "This month",
      "Last month",
      "Earlier",
    ];

    const grouped = maybeMap(days, (d) => {
      const items = orderBy(byGroup[d], (i) => toDay(i.entity), "desc");

      if (!items.length) {
        return;
      }

      pushDirty(all, ...items);
      return [d, items] as [string, InboxItem[]];
    });

    return { all, grouped, indexed };
  }, [resultsByType, settings, selection]);

  const getItem = useCallback((id: ID) => indexed[id], [indexed]);

  const allSeen = useMemo(() => every(all, (i) => !!i.seen), [all]);

  const active = useMemo(
    () => find(all, (i) => i.id === activeId),
    [activeId, activeType]
  );

  const onResults = useCallback(
    (type: EntityType, results: Entity[]) =>
      setResultsByType((r) => ({ ...r, [type]: results })),
    [setResultsByType]
  );

  const changeSetting = useCallback(
    (changes: Partial<InboxSettings>) => {
      const proposed = { ...settings, ...changes };

      // If current filter is not supported by the current show type, reset to default
      if (changes.show && !filterSupported(proposed.show, proposed.filter)) {
        changes.filter = ["following"];
      }

      setSettings({ ...settings, ...changes });
    },
    [settings]
  );

  const replaceFilter = useCallback(
    (val: InnerType<InboxSettings["filter"]>) =>
      changeSetting({ filter: [val] }),
    [settings]
  );

  const handleMarkAsSeen = useCallback(
    (item?: InboxItem) => {
      const idsToUpdate =
        item && isSelected(selection, item.id)
          ? toArray(selection.selected)
          : !!item
          ? [item.id]
          : map(all, (a) => a.id);

      const updates = maybeMap(idsToUpdate, (i) => {
        const item = getItem(i);

        if (!item) {
          return;
        }

        // Don't update if already seen
        if (containsRef((item.entity as HasRefs)?.refs?.seenBy, me)) {
          return;
        }

        return asUpdate(
          item.entity,
          asAppendMutation({ field: "refs.seenBy", type: "relations" }, [
            toRef(me),
          ])
        );
      });

      mutate(updates);
    },
    [selection, all]
  );

  const handleMarkAsUnSeen = useCallback(
    (item: InboxItem) => {
      const idsToUpdate =
        item && isSelected(selection, item.id)
          ? toArray(selection.selected)
          : [item.id];

      const updates = maybeMap(idsToUpdate, (i) => {
        const item = getItem(i);

        if (!item) {
          return undefined;
        }

        return asUpdate(
          item.entity,
          asAppendMutation(
            { field: "refs.seenBy", type: "relations" },
            [toRef(me)],
            "remove"
          )
        );
      });

      setSelection(clearSelection());
      mutate(updates);
    },
    [selection, all]
  );

  if (!me) {
    return <></>;
  }

  return (
    <Sheet size="full" transparency="low" interactable={false}>
      {/* Component Loaders */}
      <InboxLoader
        settings={settings}
        type={"note"}
        onResults={onResults}
        scope={scope}
      />
      {settings.show === "all" &&
        map(entities, (entity) => (
          <InboxLoader
            key={entity}
            settings={settings}
            type={entity}
            onResults={onResults}
            scope={scope}
          />
        ))}

      <PageLayout>
        <SideNav className={styles.nav}>
          <VStack gap={20}>
            <SpaceBetween>
              <HStack>
                <Heading bold>Inbox</Heading>
                <Tooltip text="This feature is still in development.">
                  <Tag size="small" color="orange_3">
                    Beta
                  </Tag>
                </Tooltip>
              </HStack>

              <HStack gap={4}>
                <Tooltip text="Show/Hide seen items" side="bottom">
                  <Switch
                    subtle
                    size="small"
                    label={"Hide seen"}
                    labelPosition="before"
                    checked={!!settings.hideSeen}
                    onChange={() =>
                      changeSetting({ hideSeen: !settings.hideSeen })
                    }
                  />
                </Tooltip>

                {!isWorkspaceId(space.id) && (
                  <SpaceFilterSelect
                    value={scope}
                    onChanged={(t) =>
                      changeSetting({
                        scope: isWorkspaceId(t) ? "all" : "space",
                      })
                    }
                    caret={true}
                    searchable={false}
                    casing="title"
                  />
                )}
              </HStack>
            </SpaceBetween>

            <SpaceBetween>
              <TabBar
                options={TAB_BAR_ITEMS}
                active={settings.show}
                onActiveChanged={(id) =>
                  changeSetting({ show: id as "all" | "note" })
                }
              />
              <HStack gap={0}>
                {/* TODO: Move as convenience into Button */}
                {!allSeen && (
                  <Tooltip
                    delay={0}
                    text={
                      selection?.selected.size > 1
                        ? `Mark (${selection?.selected.size}) as seen`
                        : "Mark all as seen"
                    }
                  >
                    <Button
                      icon={MarkAsSeen}
                      onClick={() => handleMarkAsSeen()}
                      subtle
                      size="small"
                    />
                  </Tooltip>
                )}

                <ActionMenu
                  actions={
                    <>
                      <ActionItem
                        onClick={() => replaceFilter("following")}
                        checked={equalsAny("following", settings?.filter)}
                        text="Following"
                      />
                      {settings.show === "all" && (
                        <ActionItem
                          onClick={() => replaceFilter("assigned")}
                          checked={equalsAny("assigned", settings?.filter)}
                          text="My work"
                        />
                      )}
                      {settings.show === "note" && (
                        <ActionItem
                          onClick={() => replaceFilter("mentioned")}
                          checked={equalsAny("mentioned", settings?.filter)}
                          text="Mentions me"
                        />
                      )}
                      <ActionItem
                        onClick={() => replaceFilter("created")}
                        checked={equalsAny("created", settings?.filter)}
                        text={
                          settings.show === "note"
                            ? "Sent by me"
                            : "Created by me"
                        }
                      />
                    </>
                  }
                >
                  <Button icon={FilterAlt} subtle size="small">
                    {when(settings.filter[0], sentenceCase)}
                  </Button>
                </ActionMenu>
              </HStack>
            </SpaceBetween>

            <Divider />

            <VStack fit="container" gap={20}>
              {map(grouped, ([day, items]) => (
                <VStack key={day} fit="container" gap={0}>
                  <Text bold>{day}</Text>

                  <VStack fit="container" gap={0}>
                    {map(items, (item) => (
                      <Container key={item.id} padding="none" inset="left">
                        <ContextMenu
                          actions={
                            <>
                              {item.seen && (
                                <ContextItem
                                  icon={EyeSlash}
                                  text="Mark as unseen"
                                  onClick={() => handleMarkAsUnSeen(item)}
                                />
                              )}
                              {!item.seen && (
                                <ContextItem
                                  icon={Eye}
                                  text="Mark as seen"
                                  onClick={() => handleMarkAsSeen(item)}
                                />
                              )}
                              <ContextItem
                                icon={ArrowUpRight}
                                text="Open"
                                onClick={() =>
                                  pushTo(item.openTarget || item.entity)
                                }
                              />
                            </>
                          }
                        >
                          <InboxRow
                            item={item}
                            selection={selection}
                            setSelection={setSelection}
                            onOpen={(i) =>
                              pushTo(
                                isNote(i)
                                  ? last(fromScope(i.source.scope)) || i
                                  : i
                              )
                            }
                          />
                        </ContextMenu>
                      </Container>
                    ))}
                  </VStack>
                </VStack>
              ))}

              {!grouped.length && (
                <Container stack="vertical" align="center" gap={0}>
                  <Icon size="xlarge" icon={<EmojiIcon emoji="🧘" />} />
                  <EmptyState text="Your inbox is empty...">
                    {settings.hideSeen && (
                      <Text className={styles.placeholder}>
                        (Hiding seen updates)
                      </Text>
                    )}
                  </EmptyState>
                </Container>
              )}
            </VStack>
          </VStack>
        </SideNav>

        <Main>
          {!!activeId && !!active && (
            <IgnoreSelectable>
              <SpaceBetween
                direction="vertical"
                fit="container"
                gap={4}
                className={styles.paneRight}
              >
                <Container stack="vertical" padding="horizontal" gap={16}>
                  <SpaceBetween>
                    <Button
                      subtle
                      size="small"
                      onClick={() => handleMarkAsUnSeen(active)}
                    >
                      Mark Unseen
                    </Button>

                    <Button
                      variant="primary"
                      iconRight={ArrowUpRight}
                      size="small"
                      onClick={() => pushTo(active.openTarget || active.entity)}
                    >
                      Open
                    </Button>
                  </SpaceBetween>
                  <Divider />
                </Container>

                <FillSpace
                  direction="vertical"
                  fit="container"
                  width="container"
                  overflow="scroll"
                >
                  {!!engine?.asPrimaryPane &&
                    render(engine.asPrimaryPane, {
                      id: activeId,
                      item: active.entity,
                      size: "full",
                      className: styles.noPane,
                    })}

                  {!engine?.asPrimaryPane && (
                    <Container
                      stack="vertical"
                      fit="container"
                      height="container"
                      align="center"
                    >
                      <TextLarge bold>Open work to see details.</TextLarge>
                      <Button
                        iconRight={ArrowUpRight}
                        onClick={() => pushTo(activeId)}
                      >
                        Open
                      </Button>
                    </Container>
                  )}
                </FillSpace>
              </SpaceBetween>
            </IgnoreSelectable>
          )}

          {!(activeId || active) && (
            <Container className={styles.empty}>
              <EmptyState
                text="Nothing selected..."
                image="/IMG-list-empty.png"
              />
            </Container>
          )}
        </Main>
      </PageLayout>
    </Sheet>
  );
};

export default InboxPage;

const InboxRow = ({
  item: { entity, title, subtitle, type },
  selection,
  setSelection,
  onOpen,
}: {
  item: InboxItem;
  onOpen?: Fn<Ref, void>;
  selection: SelectionState;
  setSelection: SetSelectionState;
}) => {
  const seen = useHasSeen(entity);

  // TODO: THis is not accurate.... need to store WHO made last changes in stamps
  // Or switch to reading all mutations and using that as the datasource instead of timestamps...
  const actor = useMemo(
    () =>
      switchEnum(type, {
        created: () => safeAs<HasCreatedBy>(entity)?.createdBy,
        // TODO: THis is not accurate.... need to store WHO made last changes in stamps
        progressed: () =>
          safeAs<HasAssigned>(entity)?.assigned ||
          safeAs<HasOwner>(entity)?.owner,
        assigned: () => safeAs<HasCreatedBy>(entity)?.createdBy,
        // TODO: THis is not accurate.... need to store WHO made last changes in stamps
        messaged: () => safeAs<HasCreatedBy>(entity)?.createdBy,
      }) ||
      // Fallback to creator
      safeAs<HasCreatedBy>(entity)?.createdBy,
    [type]
  );
  return (
    <SelectableListItem
      key={entity.id}
      item={entity}
      selection={selection}
      setSelection={setSelection}
      className={styles.inboxItem}
      onOpen={onOpen}
    >
      <SpaceBetween gap={10} align="flex-start">
        {when(actor, (by) => (
          <RelationTooltip relation={by} side="top">
            <Icon icon={<RelationIcon relation={by} />} size="large" />
          </RelationTooltip>
        ))}

        <FillSpace>
          <VStack gap={0}>
            <SpaceBetween>
              <Text bold subtle={seen}>
                {title}
              </Text>

              <HStack className={styles.relative}>
                {!seen && <UnreadIndicator className={styles.unread} />}
                {when(fromPointDate(toDay(entity)), (date) => (
                  <TextSmall subtle={seen}>
                    {formatShort(date)} {formatTime(date)}
                  </TextSmall>
                ))}
              </HStack>
            </SpaceBetween>

            {!!subtitle && <Text subtle={seen}>{subtitle}</Text>}
          </VStack>
        </FillSpace>
      </SpaceBetween>
    </SelectableListItem>
  );
};

const InboxLoader = ({
  type,
  settings,
  onResults,
  scope,
}: {
  type: EntityType;
  settings: InboxSettings;
  onResults: (type: EntityType, results: Entity[]) => void;
  scope: string;
}) => {
  const me = useMe();
  const filter = useMemo(
    () => toFilter(type, omit(settings, "hideSeen"), me, scope),
    [settings.filter, settings.scope, settings.show, type]
  );
  const results = useLazyFetchResults("inbox-" + type, type, filter, {
    templates: false,
    archived: false,
  });

  useEffect(() => {
    onResults(type, results);
  }, [type, results]);

  return <></>;
};

function toFilter(
  type: EntityType,
  settings: InboxSettings,
  me: Person,
  scope: string
): FilterQuery<Entity> {
  if (type === "note") {
    return {
      and: omitEmpty([
        // Created in the time period but not by me
        {
          and: omitEmpty([
            {
              field: "createdAt",
              type: "date",
              op: "after",
              value: { formula: "=today-14d" },
            },
            // Unless specifically filtering for sent by me, hide my own notes
            ifDo(settings.filter?.[0] !== "created", () => ({
              field: "createdBy",
              type: "relation",
              op: "does_not_equal",
              value: { relation: toRef(me) },
            })),
          ]),
        },

        // In correct location
        ifDo(!isWorkspaceId(scope), (scope) => ({
          field: "location",
          type: "text",
          op: "starts_with",
          value: { text: scope },
        })),

        // Following/assigned rules
        switchEnum(settings.filter?.[0], {
          created: () =>
            ({
              field: "createdBy",
              type: "relation",
              op: "equals",
              value: { relation: { id: me.id } },
            } as FilterQuery<Entity>),

          // Work i'm following
          following: () =>
            ({
              field: "refs.followers",
              type: "relations",
              op: "equals",
              value: { relations: [toRef(me.id)] },
            } as FilterQuery<Entity>),

          // Hack?
          // Copy of following since we can't filter on the parent entity assigned
          assigned: () =>
            ({
              field: "refs.followers",
              type: "relations",
              op: "equals",
              value: { relations: [toRef(me.id)] },
            } as FilterQuery<Entity>),

          // Body contains my display name
          mentioned: () =>
            ({
              field: "body",
              type: "rich_text",
              op: "contains",
              value: { rich_text: { html: toDisplayName(me) } },
            } as Maybe<FilterQuery<Entity>>),

          else: () => undefined,
        }),
      ]) as FilterQuery<Entity>[],
    };
  }

  // Non-note entities (e.g. tasks, outcomes, etc)
  return {
    and: omitEmpty([
      {
        or: [
          {
            and: [
              {
                field: "createdAt",
                type: "date",
                op: "after",
                value: { formula: "=today-14d" },
              },
              // Unless specifically filtering for sent by me, hide my own notes
              ifDo(settings.filter?.[0] !== "created", () => ({
                field: "createdBy",
                type: "relation",
                op: "does_not_equal",
                value: { relation: toRef(me) },
              })),
            ],
          },
          {
            field: "stamps.status.at",
            type: "date",
            op: "after",
            value: { formula: "=today-14d" },
          },
          {
            field: "stamps.notes.at",
            type: "date",
            op: "after",
            value: { formula: "=today-14d" },
          },

          {
            field: "stamps.notes.at",
            type: "date",
            op: "after",
            value: { formula: "=today-14d" },
          },
          {
            field: "stamps.assigned.at",
            type: "date",
            op: "after",
            value: { formula: "=today-14d" },
          },
        ],
      },

      // Filtering by team
      when(scope, (scope) => ({
        field: "location",
        type: "text",
        op: "starts_with",
        value: { text: scope },
      })),

      // Ignore draft work
      ifDo(
        equalsAny(type, ["task", "outcome", "campaign", "content", "project"]),
        () => ({
          field: "status",
          type: "status",
          op: "does_not_equal",
          value: { status: { group: "planning" } },
        })
      ),

      // Following/assigned rules
      switchEnum(settings.filter?.[0], {
        created: () =>
          ({
            field: "createdBy",
            type: "relation",
            op: "equals",
            value: { relation: { id: me.id } },
          } as FilterQuery<Entity>),

        // Work i'm following that isn't assigned to me
        following: () => ({
          and: [
            {
              field: "refs.followers",
              type: "relations",
              op: "equals",
              value: { relations: [toRef(me.id)] },
            } as FilterQuery<Entity>,
          ],
        }),

        // Work assigned to me that I didn't create
        assigned: () => ({
          and: omitEmpty([
            {
              or: [
                {
                  field: "createdBy",
                  type: "relation",
                  op: "does_not_equal",
                  value: { relation: { id: me.id } },
                },
                {
                  field: "stamps.notes.at",
                  type: "date",
                  op: "after",
                  value: { formula: "=today-14d" },
                },
              ],
            } as FilterQuery<Entity>,

            fallback(
              () =>
                ifDo(equalsAny(type, ["task", "outcome"]), () => ({
                  field: "assigned",
                  type: "relation",
                  op: "equals",
                  value: { relation: { id: me.id } },
                })) as Maybe<FilterQuery<Entity>>,

              ifDo(
                equalsAny(type, [
                  "content",
                  "calendar",
                  "project",
                  "roadmap",
                  "campaign",
                ]),
                () => ({
                  field: "owner",
                  type: "relation",
                  op: "equals",
                  value: { relation: { id: me.id } },
                })
              ) as Maybe<FilterQuery<Entity>>,

              // Work is not assignable, so should never show up
              () =>
                ({
                  field: "updatedAt",
                  type: "date",
                  op: "before",
                  value: { date: "1000-01-01T00:00:00" },
                } as Maybe<FilterQuery<Entity>>)
            ),
          ]),
        }),

        else: () => undefined,
      }),
    ]) as FilterQuery<Entity>[],
  };
}
