import { Dictionary, first, keys, map, reduce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Entity,
  EntityRef,
  EntityType,
  HasName,
  HasTitle,
  Ref,
  toTitleOrName,
} from "@api";

import {
  useBaseSource,
  useDuplicate,
  useLazyEntity,
  useNestedEntities,
} from "@state/generic";

import { Fn } from "@utils/fn";
import { mask, maybeValues } from "@utils/object";
import { plural } from "@utils/string";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack, VStack } from "@ui/flex";
import { SpinnerIcon } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { Modal } from "@ui/modal";
import { Text, TextLarge } from "@ui/text";

interface Props {
  target: Ref;
  defaults?: Partial<Entity>;
  onComplete?: Fn<EntityRef, void>;
  onCancel?: Fn<void, void>;
}

export const TemplateCreateDialog = ({
  target,
  defaults,
  onCancel,
  onComplete,
}: Props) => {
  const entity = useLazyEntity(target.id);
  const { children, loading } = useNestedEntities(entity);
  const duplicate = useDuplicate(useBaseSource(entity), {
    [target.id]: { template: "root" },
    "*": { template: "nested" },
  });
  const [opts, setDuplicate] = useState<Partial<Record<EntityType, boolean>>>(
    {}
  );
  const [name, setName] = useState("");

  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );

  const handleSubmit = useCallback(() => {
    if (!entity) {
      return;
    }

    // Add the name/title to the entity incase it was edited
    const neww = duplicate.run(
      entity,
      mask(children as Dictionary<Entity[]>, opts),
      {
        appendName: false,
        overrides: { [entity.id]: { ...defaults, name: name } },
      }
    );
    onComplete?.(first(neww) as EntityRef);
  }, [duplicate.run, defaults, entity, opts, name]);

  useEffect(
    () => entity && setName(toTitleOrName(entity)),
    [(entity as HasName)?.name, (entity as HasTitle)?.title]
  );

  useEffect(() => {
    setDuplicate(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  if (!entity) {
    return <></>;
  }

  return (
    <Modal open={true} onOpenChanged={() => onCancel?.()}>
      <Container stack="vertical" padding="none" gap={20}>
        <VStack gap={4}>
          <TextLarge bold>Save as template</TextLarge>
          <Text>
            This {entity.source.type} will be saved as a template, including all
            nested work.
          </Text>
        </VStack>

        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Container padding="none" inset="horizontal">
              <Field label="Template name">
                <TextInput
                  value={name}
                  onChange={setName}
                  placeholder="Template name..."
                  updateOn="change"
                />
              </Field>
            </Container>
            <Menu>
              <MenuGroup label="Include">
                {!loading && (
                  <CheckMenuItem disabled checked={true} onChecked={() => {}}>
                    This {entity.source.type}
                  </CheckMenuItem>
                )}

                {loading && (
                  <MenuItem disabled icon={SpinnerIcon}>
                    Collecting nested work...
                  </MenuItem>
                )}

                {!loading && hasChildren && (
                  <>
                    {!hasChildren && <MenuItem text="No nested work" />}
                    {map(children, (values, type) => (
                      <CheckMenuItem
                        key={type}
                        checked={opts[type as EntityType] ?? false}
                        onChecked={() =>
                          setDuplicate((d) => ({
                            ...d,
                            [type]: !(opts[type as EntityType] ?? false),
                          }))
                        }
                      >
                        Nested {plural(type, values?.length || 0)} (
                        {values?.length || 0})
                      </CheckMenuItem>
                    ))}
                  </>
                )}
              </MenuGroup>
            </Menu>
          </Container>
        </FillSpace>

        <HStack gap={4} fit="container" justify="flex-end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            loading={duplicate.running}
          >
            Save as template
          </Button>
        </HStack>
      </Container>
    </Modal>
  );
};
