import { Portal } from "@radix-ui/react-portal";
import { filter, find, map, orderBy, take } from "lodash";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import {
  Entity,
  EntityType,
  ID,
  isEntity,
  isTeam,
  Person,
  Team,
  toTitleOrName,
  View,
} from "@api";

import {
  useAppCommandsAction,
  useOpenAppCommands,
  useOpenPeople,
  useOpenRecents,
} from "@state/app";
import { GlobalFetchOptionsAtom, showArchived } from "@state/fetch-results";
import {
  useLazyEntities,
  useLazyEntity,
  useLazyQuery,
  useUpdateEntity,
} from "@state/generic";
import { usePinTo } from "@state/generic/effects/pinning";
import { useGetPackage } from "@state/packages";
import { useLazyAllPeople, useMe } from "@state/persons";
import { useSortByRecentlyViewed } from "@state/recents";
import { useActiveSpace, useSpaceState } from "@state/spaces";
import { toManagePackages, useLazyAllTeams, useTeamPushTo } from "@state/teams";
import { useLazyViewsForFilter } from "@state/views";
import { useActiveWorkspaceId, useCurrentWorkspace } from "@state/workspace";

import { maybeLookupById } from "@utils/array";
import { cx } from "@utils/class-names";
import { useShortcut, useWindowEvent, withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import {
  useCancellableTimeout,
  useClickAway,
  useShowMore,
  useStickyState,
} from "@utils/hooks";
import {
  isPersonId,
  isTeamId,
  isWorkspaceId,
  maybeTypeFromId,
} from "@utils/id";
import { equalsAny, switchEnum } from "@utils/logic";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { useGoTo, usePathName } from "@utils/navigation";
import { asAppendMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { toParentScope, toRouterScope } from "@utils/scope";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Divider } from "@ui/divider";
import { Ellipsis } from "@ui/ellipsis";
import { render, useEngine } from "@ui/engine";
import { TeamCreateDialog } from "@ui/engine/team";
import { ViewCreateDialog } from "@ui/engine/view";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  AngleDoubleLeftB,
  AngleDoubleRightB,
  Archive,
  ArchiveOpen,
  ClockHistory,
  Cog,
  CompanyFilled,
  EmojiIcon,
  EyeSlash,
  Icon,
  ImageIcon,
  MilestoneFilled,
  PeopleFilled,
  PersonIcon,
  Pin,
  PinSlash,
  PlusIcon,
  Search,
  SpaceIcon,
  SquareIcon,
  StatusLive,
  TeamIcon,
  Traction,
  WorkflowAdd,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { CollapsibleMenuItem } from "@ui/menu-item/collapsible";
import { ExpandableMenuItem } from "@ui/menu-item/expandable";
import { OnHover } from "@ui/on-hover";
import { TeamMenu } from "@ui/page/team-page";
import { useIsLargeScreen } from "@ui/responsive";
import { GlobalEntitySelect } from "@ui/select";
import { WithSuggestedActions } from "@ui/suggested-actions";
import { Tag } from "@ui/tag";
import { Text, TextSmall, TextXLarge } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import { AlphaFeature } from "./feature-flag";
import { PersonStatusIcon } from "./person-status-icon";
import { RelationIcon, RelationLabel, RelationText } from "./relation-label";
import { SpacesOverlay } from "./spaces-overlay";
import { WorkspaceSelect } from "./workspace-select";

import styles from "./primary-nav.module.css";

type SubProps = {
  collapsed: boolean;
  setCollapsed: Fn<boolean, void>;
  onDismiss?: Fn<void, void>;
};

function PrimaryNavOuter({ collapsed, setCollapsed, onDismiss }: SubProps) {
  const me = useMe();
  const wID = useActiveWorkspaceId();
  const workspace = useCurrentWorkspace();
  const goTo = useGoTo();

  const [showSpacesOverlay, setShowSpacesOverlay] = useState(false);
  const [space, setSpace] = useSpaceState();
  const goToSpace = useCallback(
    (id: string) => {
      if (isPersonId(id) && me.id !== id) {
        goTo(id);
        onDismiss?.();
        return;
      }

      setSpace(id);
      goTo(isTeamId(id) ? id : isWorkspaceId(id) ? "/teams" : "/home");
    },
    [setSpace, goTo, me.id]
  );

  const [extraTeamId, setExtraTeamId] = useState<Maybe<ID>>(undefined);
  const { pin, unpin } = usePinTo();
  const isPinned = useMemo(
    () => maybeLookupById(me?.refs?.pins || []),
    [me?.refs?.pins]
  );
  const allTeams = useLazyAllTeams();
  const extraTeam = useMemo(
    () => find(allTeams, { id: extraTeamId }),
    [allTeams, extraTeamId]
  );
  const pinnedTeams = useMemo(
    () =>
      orderBy(
        filter(allTeams, (t) => !!isPinned(t.id)),
        (t) => t.name
      ),
    [allTeams, isPinned]
  );

  useEffect(() => {
    if (isTeamId(space.id) && !isPinned(space.id)) {
      setExtraTeamId(space.id);
    }
  }, [space.id]);

  const teams = useMemo(() => {
    const pins = take(pinnedTeams, 8);
    return extraTeam && !isPinned(extraTeam?.id) ? [...pins, extraTeam] : pins;
  }, [pinnedTeams, extraTeam]);

  const allPeople = useLazyAllPeople();
  const toSortedPeople = useSortByRecentlyViewed(allPeople);
  // Only resort if the list of people changes, not when sort order changes
  const slowSorted = useMemo(
    () => filter(toSortedPeople(), (p) => p.id !== me.id),
    [allPeople]
  );
  const people = useShowMore(slowSorted, 5);
  const openPeople = useOpenPeople();

  return (
    <OnHover.Trigger group="outer-nav">
      <div className={cx(styles.outerNav, collapsed && styles.collapsed)}>
        {/* Prevent mouse moves in portal from re-opening hover menu */}
        {showSpacesOverlay && (
          <Portal onMouseMove={withHardHandle(() => {})}>
            <SpacesOverlay
              onClose={() => setShowSpacesOverlay(false)}
              onChanged={setSpace}
            />
          </Portal>
        )}
        <SpaceBetween fit="container" direction="vertical">
          <VStack gap={24}>
            <Tooltip
              text={workspace?.name || "Company"}
              side="right"
              delay={0}
              hoverIn={false}
            >
              <SquareIcon
                className={cx(
                  styles.largeIcon,
                  space.id === wID && styles.selected
                )}
                color="gray_5"
                onClick={() => goToSpace(wID)}
              >
                <Icon
                  size="medium"
                  icon={
                    when(workspace?.icon, (i) => <ImageIcon url={i} />) ||
                    Traction
                  }
                />
              </SquareIcon>
            </Tooltip>

            <Tooltip text="My Work" side="right" delay={0} hoverIn={false}>
              <Icon
                className={cx(
                  styles.largeIcon,
                  styles.person,
                  space.id === me.id && styles.selected
                )}
                onClick={() => goToSpace(me.id)}
                icon={<PersonIcon person={me} />}
              />
            </Tooltip>

            <VStack gap={10}>
              {map(teams, (t) => (
                <Tooltip
                  hoverIn={false}
                  key={t.id}
                  text={t?.name || "Team"}
                  side="right"
                  delay={0}
                >
                  <ContextMenu
                    actions={
                      isPinned(t.id) ? (
                        <ContextItem
                          icon={PinSlash}
                          text="Unpin"
                          onClick={() => {
                            unpin(me, t);
                            if (space.id === t.id) {
                              setExtraTeamId(space.id);
                            }
                          }}
                        />
                      ) : (
                        <ContextItem
                          icon={Pin}
                          text="Pin"
                          onClick={() => {
                            if (extraTeamId === t.id) {
                              setExtraTeamId(undefined);
                            }
                            pin(me, t);
                          }}
                        />
                      )
                    }
                  >
                    <TeamIcon
                      team={t}
                      className={cx(
                        styles.largeIcon,
                        space.id === t.id && styles.selected
                      )}
                      indicator={
                        isPinned(t.id) && (
                          <OnHover.Target group="outer-nav">
                            <Tag size="xsmall" className={styles.pinIndicator}>
                              <Icon size="xsmall" icon={Pin} />
                            </Tag>
                          </OnHover.Target>
                        )
                      }
                      onClick={() => goToSpace(t.id)}
                    />
                  </ContextMenu>
                </Tooltip>
              ))}

              <Tooltip text="All teams" side="right" delay={0} hoverIn={false}>
                <SquareIcon
                  className={cx(styles.largeIcon, styles.subtle)}
                  onClick={() => {
                    setShowSpacesOverlay(true);
                    onDismiss?.();
                  }}
                >
                  <Icon icon={CompanyFilled} />
                </SquareIcon>
              </Tooltip>
            </VStack>
          </VStack>

          <VStack gap={10}>
            {map(people.visible, (p) => (
              <Tooltip
                hoverIn={false}
                key={p.id}
                text={p?.name || "View Work"}
                side="right"
                delay={0}
              >
                <Icon
                  className={cx(styles.largeIcon, styles.person)}
                  onClick={() => goToSpace(p.id)}
                  icon={<PersonStatusIcon person={p} />}
                />
              </Tooltip>
            ))}

            {people.hasMore && (
              <SquareIcon
                className={cx(styles.largeIcon, styles.person, styles.subtle)}
                onClick={() => openPeople()}
              >
                <Icon icon={PeopleFilled} />
              </SquareIcon>
            )}
          </VStack>
        </SpaceBetween>
      </div>
    </OnHover.Trigger>
  );
}

function PrimaryNavInner({ collapsed, setCollapsed, onDismiss }: SubProps) {
  const goTo = useGoTo();
  const me = useMe();
  const wID = useActiveWorkspaceId();
  const path = useLocation();
  const fullMe = useLazyEntity<"person">(me.id);
  const getPackage = useGetPackage();
  const allPins = useLazyEntities(fullMe?.refs?.pins);
  const pinned = useMemo(
    () => filter(allPins, (p) => p.source.type !== "team"),
    [allPins]
  );
  const [fetchOptions, setFetchOptions] = useRecoilState(
    GlobalFetchOptionsAtom
  );
  const mutate = useUpdateEntity(me.id);
  const [dialog, setDialog] = useState<
    "view" | "team" | "add-work" | "space"
  >();
  const setCommand = useAppCommandsAction();

  const space = useActiveSpace();
  const openSearch = useOpenAppCommands();
  const openRecents = useOpenRecents();

  return (
    <OnHover.Trigger group="primary-nav">
      <div className={cx(styles.innerNav)}>
        {dialog === "team" && (
          <TeamCreateDialog
            defaults={{}}
            onCancel={() => setDialog(undefined)}
            onSaved={(t) => {
              setDialog(undefined);
              goTo(toManagePackages(t.id));
            }}
          />
        )}
        {dialog === "view" && (
          <ViewCreateDialog
            defaults={{}}
            onCancel={() => setDialog(undefined)}
            onSaved={(view) => {
              goTo(view);
              setDialog(undefined);
            }}
          />
        )}

        <Container
          fit="container"
          padding="top"
          stack="vertical"
          align="center"
          gap={10}
        >
          {space.mode === "workspace" ? (
            <SpaceBetween fit="container" gap={0}>
              {/* Lol don't just stand there, clean it up */}
              <Container inset="both" padding="none">
                <Container inset="both" padding="none">
                  <WorkspaceSelect className={styles.fit}>
                    <Ellipsis className={styles.oneLine}>
                      <TextXLarge bold>
                        {space.entity?.name || "Company"}
                      </TextXLarge>
                    </Ellipsis>
                  </WorkspaceSelect>
                </Container>
              </Container>
            </SpaceBetween>
          ) : (
            <SpaceBetween fit="container" gap={0}>
              {switchEnum(space.mode || "", {
                person: () => (
                  <>
                    <TextXLarge bold>My Work</TextXLarge>
                  </>
                ),
                else: () => (
                  <Ellipsis className={styles.oneLine}>
                    <TextXLarge bold>
                      {when(space.entity, toTitleOrName)}
                    </TextXLarge>
                  </Ellipsis>
                ),
              })}

              <OnHover.Target group="primary-nav" show={collapsed}>
                <Button
                  subtle
                  icon={collapsed ? AngleDoubleRightB : AngleDoubleLeftB}
                  onClick={() => setCollapsed(!collapsed)}
                />
              </OnHover.Target>
            </SpaceBetween>
          )}

          <Divider />

          {space.mode !== "workspace" && (
            <SpaceBetween gap={6} direction="horizontal" width="container">
              <Button
                variant="primary"
                fit="content"
                size="small"
                className={cx(styles.primaryAction, styles.newButton)}
                onClick={() => setCommand("add_work")}
              >
                <Icon icon={PlusIcon} className={styles.icon} />
              </Button>

              <>
                <AlphaFeature>
                  <Button
                    fit="content"
                    size="small"
                    subtle
                    className={cx(styles.primaryAction, styles.searchButton)}
                    onClick={() => setCommand("start_workflow")}
                  >
                    <Icon icon={WorkflowAdd} className={styles.icon} />
                  </Button>
                </AlphaFeature>

                <Button
                  subtle
                  fit="content"
                  size="small"
                  className={cx(styles.primaryAction, styles.searchButton)}
                  onClick={openRecents}
                >
                  <Icon icon={ClockHistory} />
                </Button>
                <Button
                  subtle
                  fit="content"
                  size="small"
                  className={cx(styles.primaryAction, styles.searchButton)}
                  onClick={openSearch}
                >
                  <Icon icon={Search} />
                </Button>
              </>
            </SpaceBetween>
          )}

          <CurrentMeetingButton />

          <Divider />
        </Container>

        <VStack
          className={styles.fill}
          fit="container"
          gap={20}
          onClick={() => onDismiss?.()}
        >
          {space.mode === "workspace" && (
            <Menu>
              <MenuGroup className={styles.group}>
                <CollapsibleMenuItem
                  className={styles.menuItem}
                  onClick={() => goTo("/teams")}
                  icon={CompanyFilled}
                  selected={path?.pathname?.includes("/teams")}
                >
                  Teams
                </CollapsibleMenuItem>

                <CollapsibleMenuItem
                  className={styles.menuItem}
                  // onClick={() => goTo("/people")}
                  tooltip="People and roles."
                  icon={PeopleFilled}
                >
                  <SpaceBetween>
                    People
                    <Tag size="small">
                      <TextSmall subtle>Coming...</TextSmall>
                    </Tag>
                  </SpaceBetween>
                </CollapsibleMenuItem>

                <CollapsibleMenuItem
                  className={styles.menuItem}
                  tooltip="Communicate and connect strategy to execution."
                  icon={MilestoneFilled}
                >
                  <SpaceBetween>
                    Vision & Strategy
                    <Tag size="small">
                      <TextSmall subtle>Coming...</TextSmall>
                    </Tag>
                  </SpaceBetween>
                </CollapsibleMenuItem>
              </MenuGroup>
            </Menu>
          )}

          {space.mode === "person" && (
            <Menu>
              <MenuGroup className={styles.group}>
                <CollapsibleMenuItem
                  className={styles.menuItem}
                  onClick={() => goTo("/home")}
                  icon={<PersonIcon person={me} />}
                  selected={path?.pathname?.includes("/home")}
                >
                  Home
                </CollapsibleMenuItem>

                <CollapsibleMenuItem
                  className={styles.menuItem}
                  onClick={() => goTo("/inbox")}
                  icon={<EmojiIcon emoji="📥" />}
                  selected={path?.pathname?.includes("/inbox")}
                >
                  Inbox
                </CollapsibleMenuItem>

                <CollapsibleMenuItem
                  className={styles.menuItem}
                  onClick={() => goTo("/outbox")}
                  icon={<EmojiIcon emoji="📤" />}
                  selected={path?.pathname?.includes("/outbox")}
                >
                  Follow-up
                </CollapsibleMenuItem>

                <CollapsibleMenuItem
                  className={styles.menuItem}
                  onClick={() => goTo(["meetings"])}
                  icon={<EmojiIcon emoji="🤝" />}
                  selected={path?.pathname?.startsWith("/meetings")}
                >
                  My Meetings
                </CollapsibleMenuItem>

                <CollapsibleMenuItem
                  className={styles.menuItem}
                  onClick={() => goTo(["notepad"])}
                  icon={<EmojiIcon emoji="✍️" />}
                  selected={path?.pathname?.startsWith("/notepad")}
                >
                  My Notepad
                </CollapsibleMenuItem>
              </MenuGroup>

              <MenuGroup className={styles.group}>
                {maybeMap(pinned, (v) => (
                  <ContextMenu
                    key={v.id}
                    actions={
                      <ContextItem
                        icon={PinSlash}
                        onClick={() =>
                          mutate(
                            asAppendMutation(
                              { field: "refs.pins", type: "relations" },
                              [toRef(v)],
                              "remove"
                            )
                          )
                        }
                      >
                        Unpin
                      </ContextItem>
                    }
                  >
                    <CollapsibleMenuItem
                      className={styles.menuItem}
                      onClick={() =>
                        v.source.type === "view" ? goTo([me, v]) : goTo(v)
                      }
                      tooltip={
                        <TextSmall>
                          <RelationText size="small" relation={v} />
                        </TextSmall>
                      }
                      selected={path?.pathname?.endsWith(v.id)}
                      icon={
                        isEntity(v, "view") ? (
                          getPackage(v.entity)?.icon || (
                            <RelationIcon relation={v} />
                          )
                        ) : (
                          <RelationIcon relation={v} />
                        )
                      }
                    >
                      <SpaceBetween>
                        <RelationLabel icon={false} relation={v} />
                      </SpaceBetween>
                    </CollapsibleMenuItem>
                  </ContextMenu>
                ))}

                <GlobalEntitySelect
                  value={undefined}
                  scope={wID}
                  type="team"
                  allowed="*"
                  onChange={(r) =>
                    r &&
                    mutate(
                      asAppendMutation(
                        { field: "refs.pins", type: "relations" },
                        [r]
                      )
                    )
                  }
                >
                  <CollapsibleMenuItem icon={PlusIcon}>
                    <Text subtle>Pin anything</Text>
                  </CollapsibleMenuItem>
                </GlobalEntitySelect>
              </MenuGroup>
            </Menu>
          )}

          {space.mode === "team" &&
            when(safeAs<Team>(space.entity), (team) => (
              <TeamMenu team={team} showPinned={false} />
            ))}

          {space.mode === "person" &&
            when(safeAs<Person>(space.entity), (person) => (
              <PrivateMenu
                me={person}
                active={path?.pathname}
                onActiveChanged={(id) => goTo([person.id, id])}
                pageId={path.pathname}
              />
            ))}
        </VStack>

        <Container className={cx(styles.bottom)} padding="none">
          <Divider />
          <Menu>
            <MenuGroup className={styles.group}>
              <CollapsibleMenuItem
                className={cx(
                  styles.menuItem,
                  styles.archived,
                  fetchOptions?.archived && styles.open
                )}
                onClick={() =>
                  setFetchOptions(showArchived(!fetchOptions?.archived))
                }
                icon={fetchOptions?.archived ? ArchiveOpen : Archive}
              >
                {fetchOptions?.archived ? "Close archives" : "Open archives"}
              </CollapsibleMenuItem>

              <CollapsibleMenuItem
                className={styles.menuItem}
                icon={Cog}
                onClick={() => goTo("/settings/personal")}
              >
                Settings
              </CollapsibleMenuItem>

              <CollapsibleMenuItem
                className={styles.menuItem}
                icon={me && <PersonIcon person={me} />}
                onClick={() => goTo("/settings/personal")}
              >
                {me?.name}
              </CollapsibleMenuItem>
            </MenuGroup>
          </Menu>
        </Container>
      </div>
    </OnHover.Trigger>
  );
}

interface TeamMenuProps {
  team: Team | Person;
  highlighted?: boolean;
  collapsed?: boolean;
  onEnterSpace?: Fn<ID, void>;
}

export const TeamMenuItem = ({
  team,
  collapsed,
  highlighted,
  onEnterSpace,
}: TeamMenuProps) => {
  const goTo = useGoTo();
  const pushTo = useTeamPushTo(team.id);

  if (!team) {
    return <></>;
  }

  if (collapsed) {
    return (
      <CollapsibleMenuItem
        selected={highlighted}
        className={cx(styles.menuItem)}
        collapsed={true}
        icon={
          isTeam(team) ? (
            <TeamIcon team={team} />
          ) : (
            <SquareIcon color="gray_5">
              <EyeSlash />
            </SquareIcon>
          )
        }
        onClick={() => onEnterSpace?.(team.id)}
      >
        <Text>{isTeam(team) ? team.name : "Private"}</Text>
      </CollapsibleMenuItem>
    );
  }

  return (
    <ExpandableMenuItem
      saveKey={team.id}
      selected={highlighted}
      className={cx(
        styles.menuItem,
        styles.teamItem,
        styles.onHover,
        styles.team
      )}
      onClick={() => onEnterSpace?.(team.id)}
      size="medium"
      exapandable={!!team?.refs?.pins?.length}
      highlight="shadow"
      icon={
        <Icon
          className={styles.teamIcon}
          icon={<SpaceIcon entity={team} />}
          size="medium"
        />
      }
      text={isTeam(team) ? team.name : "Private"}
    >
      <>
        {map(team?.refs?.pins, (r) => (
          <CollapsibleMenuItem
            key={r.id}
            collapsed={collapsed ?? false}
            indent={1}
            onClick={() => pushTo(r)}
            icon={<RelationIcon relation={r} />}
          >
            <RelationLabel icon={false} relation={r} />
          </CollapsibleMenuItem>
        ))}
      </>
    </ExpandableMenuItem>
  );
};

const CurrentMeetingButton = () => {
  const goTo = useGoTo();
  const me = useMe();

  const [current] = useLazyQuery(
    "meeting",
    useMemo(
      () => ({
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            value: { status: { group: "in-progress" } },
          },
          {
            field: "refs.people",
            type: "relations",
            op: "equals",
            value: { relations: [toRef(me)] },
          },
        ],
      }),
      [me?.id]
    ),
    { limit: 1 }
  );

  return useMemo(
    () =>
      !!current && (
        <Button
          subtle
          variant="danger"
          fit="content"
          size="small"
          className={cx(styles.meetingButton)}
          onClick={() => goTo(current)}
        >
          <HStack fit="container" gap={4} className={styles.text}>
            <Icon icon={StatusLive} />
            <Ellipsis>{current.name}</Ellipsis>
          </HStack>
        </Button>
      ),
    [current?.id, current?.name]
  );
};

export default function PrimaryNav() {
  const [hovering, setHovering] = useState(false);
  const pathName = usePathName();
  const ref = useRef<HTMLDivElement>(null);
  const [collapsedState, setCollapsed] = useStickyState<boolean>(
    false,
    "primary-nav-open"
  );
  const isLargeScreen = useIsLargeScreen();
  const autoCollapse = useMemo(() => {
    const routerType = maybeTypeFromId(toRouterScope(pathName));
    return (
      (!isLargeScreen &&
        !!routerType &&
        !equalsAny(routerType, ["team", "person", "workspace"])) ||
      (routerType !== "person" &&
        equalsAny(maybeTypeFromId(toParentScope(pathName)), ["view"])) ||
      pathName?.includes("/settings/")
    );
  }, [pathName]);

  const collapsed = autoCollapse || collapsedState;

  const [delayedOpen, cancel] = useCancellableTimeout(
    () => setHovering(true),
    400,
    []
  );

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
    setHovering(false);
  }, [collapsed]);
  useShortcut({ command: true, key: "Backslash" }, toggleCollapsed, [
    toggleCollapsed,
  ]);

  useWindowEvent("mouseout", cancel);
  useClickAway(ref, () => setHovering(false), collapsed);

  useLayoutEffect(() => {
    if (autoCollapse) {
      setHovering(false);
    }
  }, [autoCollapse]);

  if (collapsed) {
    return (
      <div
        ref={ref}
        className={cx(styles.hoverContainer, hovering && styles.hovering)}
        onMouseMove={(e) =>
          !hovering && (e.pageX < 45 ? setHovering(true) : delayedOpen())
        }
        onMouseLeave={() => {
          setHovering(false);
          cancel();
        }}
      >
        <div className={styles.container}>
          <PrimaryNavOuter
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            onDismiss={() => setHovering(false)}
          />

          <div className={cx(styles.overlay, hovering && styles.hovering)}>
            <PrimaryNavInner
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              onDismiss={() => setHovering(false)}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <PrimaryNavOuter collapsed={collapsed} setCollapsed={setCollapsed} />
      <PrimaryNavInner collapsed={collapsed} setCollapsed={setCollapsed} />
    </div>
  );
}

type SideHeaderProps = {
  me: Person;
  active: Maybe<ID>;
  onActiveChanged: Fn<ID, void>;
  pageId: ID;
};

const PrivateMenu = ({ me, ...rest }: SideHeaderProps) => {
  const [creating, setCreating] = useState<EntityType>();
  const pathName = usePathName();

  const all = useLazyViewsForFilter(
    useMemo(() => ({ location: me.id }), [me.id])
  );
  const views = useShowMore(all, 5);

  if (!all?.length) {
    return <></>;
  }

  return (
    <>
      {creating && (
        <ViewCreateDialog
          defaults={{
            entity: creating,
            for: undefined,
            location: me.id,
            source: { type: "view", scope: me.id },
          }}
          onSaved={(saved) => {
            setCreating(undefined);
            rest.onActiveChanged?.(saved.id);
          }}
          onCancel={() => setCreating(undefined)}
        />
      )}
      <MenuGroup>
        {map(views.visible, (view) => (
          <ViewMenuItem
            key={view.id}
            view={view}
            selected={pathName?.endsWith(view.id)}
            onClick={(v) => rest.onActiveChanged(v.id)}
          />
        ))}
        {views.hasMore && (
          <ShowMoreMenuItem count={views.moreCount} onClick={views.showMore} />
        )}
        <MenuItem icon={PlusIcon} onClick={() => setCreating("task")}>
          <Text subtle>New board</Text>
        </MenuItem>
      </MenuGroup>
    </>
  );
};

const ViewMenuItem = ({
  view,
  onClick,
  selected,
}: {
  view: View;
  selected?: boolean;
  onClick?: Fn<View, void>;
}) => {
  const me = useMe();
  const engine = useEngine("view");
  const { pin, unpin } = usePinTo();
  const contextItems = useMemo(() => {
    return [
      {
        id: "pin",
        label: "Pin",
        icon: Pin,
        onClick: (child: Entity) => pin(me, child),
      },
      {
        id: "unpin",
        label: "Unpin",
        icon: PinSlash,
        onClick: (child: Entity) => unpin(me, child),
      },
    ];
  }, [me, pin, unpin]);

  return (
    <WithSuggestedActions items={contextItems}>
      {render(engine.asMenuItem, {
        key: view.id,
        item: view,
        selected: selected,
        onOpen: () => onClick?.(view),
      })}
    </WithSuggestedActions>
  );
};
