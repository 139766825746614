import { map } from "lodash";

import { toDisplayName, useLazyAllPeople } from "@state/persons";

import { useGoTo } from "@utils/navigation";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { SpaceBetween } from "@ui/flex";
import { RelationIcon } from "@ui/relation-label";
import { Text, TextSmall } from "@ui/text";

import { AppCommandsProps } from "./types";
import { useCommandSearch } from "./utils";

export const PeopleCommands = ({ mode }: AppCommandsProps) => {
  const goTo = useGoTo();
  const people = useLazyAllPeople();
  const query = useCommandSearch();

  if (mode === "searching" && query.length > 0) {
    return <></>;
  }

  return (
    <CommandGroup label="People">
      {map(people, (t) => (
        <CommandItem
          key={t.id}
          value={`go to ${t.name} ${toDisplayName(t)} person ${t.id}`}
          icon={<RelationIcon relation={t} />}
          onClick={() => goTo(t)}
        >
          <SpaceBetween>
            <Text>{t.name}</Text>
            <TextSmall subtle>{t.name}</TextSmall>
          </SpaceBetween>
        </CommandItem>
      ))}
    </CommandGroup>
  );
};
