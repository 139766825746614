import { useSetRecoilState } from "recoil";

import { AppCommandsAtom, setCommandsAction, setMode } from "@state/app";
import { useMe } from "@state/persons";
import { useEntityLabels } from "@state/settings";
import { toTemplateViewId } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { useGoTo } from "@utils/navigation";

import { CommandDivider, CommandGroup, CommandItem } from "@ui/command-menu";
import {
  ClockHistory,
  EmojiIcon,
  KeySlash,
  PersonIcon,
  PlusIcon,
} from "@ui/icon";
import { RelationText } from "@ui/relation-label";

import { SearchCommand } from "./search";
import { AppCommandsProps } from "./types";
import { useCommandSearch } from "./utils";

export const NavigationCommands = (props: AppCommandsProps) => {
  const me = useMe();
  const wID = useActiveWorkspaceId();
  const goTo = useGoTo();
  const query = useCommandSearch();
  const setCommands = useSetRecoilState(AppCommandsAtom);
  const toEntityLabel = useEntityLabels(wID, { case: "lower" });
  const { actingType, actingOn, actingSource } = props;

  return (
    <CommandGroup>
      {actingOn?.length && (
        <>
          <CommandItem
            value={`modify edit actions for open ${toEntityLabel(actingType)}`}
            onSelectAction="clear"
            icon={KeySlash}
            onClick={() => setCommands(setMode("commands"))}
          >
            {actingSource === "selection" ? (
              `Modify ${actingOn.length} selected`
            ) : actingOn?.length === 1 ? (
              <span>
                Modify "<RelationText relation={actingOn?.[0]} />"{" "}
                {toEntityLabel(actingType)}
              </span>
            ) : (
              `Modify this ${toEntityLabel(actingType)}`
            )}
          </CommandItem>
          <CommandDivider />
        </>
      )}

      <CommandItem
        value="my work"
        icon={<PersonIcon person={me} />}
        onClick={() => goTo("/home")}
      >
        My Work
      </CommandItem>
      <CommandItem
        value="inbox"
        icon={<EmojiIcon emoji="📥" />}
        onClick={() => goTo("/inbox")}
      >
        Inbox
      </CommandItem>

      <CommandItem
        value="my meetings"
        icon={<EmojiIcon emoji="📅" />}
        onClick={() => goTo("/meetings")}
      >
        My Meetings
      </CommandItem>

      <CommandItem
        value="my notebook documents"
        icon={<EmojiIcon emoji="✍️" />}
        onClick={() => goTo("/notepad")}
      >
        My Notepad
      </CommandItem>

      <CommandItem
        value="add new work"
        icon={PlusIcon}
        onClick={() => setCommands(setCommandsAction("add_work"))}
        onSelectAction="clear"
      >
        Add work
      </CommandItem>

      {!query.length && <SearchCommand {...props} />}

      <CommandItem
        value="recently viewed"
        icon={ClockHistory}
        onClick={() => setCommands(setMode("recents"))}
        onSelectAction="clear"
      >
        Recently viewed
      </CommandItem>
    </CommandGroup>
  );
};
