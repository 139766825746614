import { find, last, map, omit } from "lodash";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Process,
  PropertyValueRef,
  RichText,
  Workflow,
  WorkflowStep,
} from "@api";

import {
  freeFormToWorkflowPlan,
  freeTextToWorkflow,
  useAiUseCase,
  useStreamAIUseCase,
} from "@state/ai";
import {
  useCreateFromObject,
  useDefaultLocation,
  useLazyEntity,
  useSearch,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useDefaultTeam } from "@state/teams";
import {
  healEntityType,
  healPropertyValue,
  useStartWorkflow,
  useWorkflowSteps,
} from "@state/workflow";
import { useActiveWorkspaceId } from "@state/workspace";

import { isFirst, isLast, replace } from "@utils/array";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { switchEnum } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { merge } from "@utils/object";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { isEmpty, join } from "@utils/rich-text";
import { toChildLocation, toParentScope } from "@utils/scope";
import { selectOnly, useSelectableState } from "@utils/selectable";
import { toStamp } from "@utils/stamp";

import { Button, Props as ButtonProps } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { DialogSplit } from "@ui/dialog-split";
import { Divider } from "@ui/divider";
import { getEngine, render } from "@ui/engine";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowLeft, Lightbulb, Magic, Search, WorkflowFilled } from "@ui/icon";
import { Field } from "@ui/input";
import { useMutate } from "@ui/mutate";
import { showError } from "@ui/notifications";
import { DocumentEditor, ReadonlyDocument, TextBox } from "@ui/rich-text";
import { SearchInput } from "@ui/search-input";
import { ColoredSection } from "@ui/section";
import { LocationSelect } from "@ui/select";
import { TextSmall, TextXLarge } from "@ui/text";
import { Tooltip } from "@ui/tooltip";
import { CollectWorkflowInputs } from "@ui/workflow-collect-dialog";

import styles from "./start-workflow-dialog.module.css";

interface Props {
  defaults?: Partial<Workflow>;
  onClose: Fn<void, void>;
}

type SubProps = Props & {
  location: string;
  setLocation: Fn<string, void>;
  collecting?: StartWorkflowState;
  setCollecting: Dispatch<SetStateAction<Maybe<StartWorkflowState>>>;
};

interface StartWorkflowState {
  workflow: Partial<Workflow>;
  steps: Partial<WorkflowStep>[];
  inputs: Maybe<PropertyValueRef[]>;
  state: "collecting" | "reviewing";
}

const ensureHasEmpty = (all: RichText[]) =>
  !isEmpty(last(all)) ? [...all, { html: "" }] : all;

type AddMode = "steps" | "free" | "template";

const ButtonTab = ({
  id,
  tooltip,
  active,
  setActive,
  ...rest
}: ButtonProps & {
  id: AddMode;
  tooltip?: string;
  active: AddMode;
  setActive: Dispatch<SetStateAction<AddMode>>;
}) => (
  <Tooltip delay={0} text={tooltip || ""} side="left">
    <Button
      className={cx(id === active && styles.activeTab)}
      subtle
      icon={WorkflowFilled}
      onClick={() => setActive(id)}
      {...rest}
    />
  </Tooltip>
);

export const StartWorkflowDialog = ({ defaults, onClose }: Props) => {
  const wId = useActiveWorkspaceId();
  const team = useDefaultTeam();
  const [location, setLocation] = useState(
    () => defaults?.location || defaults?.source?.scope || team?.id || wId
  );
  const [collecting, setCollecting] = useState<StartWorkflowState>();
  const [mode, setMode] = useState<AddMode>("steps");

  return (
    <DialogSplit
      onDismiss={onClose}
      side={
        <VStack>
          <ButtonTab
            id="steps"
            tooltip="From a list of steps."
            active={mode}
            setActive={setMode}
            icon={WorkflowFilled}
          />
          <ButtonTab
            id="free"
            tooltip="From an idea."
            active={mode}
            setActive={setMode}
            icon={Lightbulb}
          />
          <ButtonTab
            id="template"
            tooltip="From a pre-built workflows."
            active={mode}
            setActive={setMode}
            icon={Search}
          />
        </VStack>
      }
      className={{
        left: styles.left,
        modal: styles.modal,
        right: styles.right,
      }}
    >
      <SpaceBetween gap={14} fit="container" direction="vertical">
        <SpaceBetween>
          <HStack gap={4}>
            {collecting && (
              <Button
                icon={ArrowLeft}
                subtle
                onClick={() =>
                  collecting.state === "reviewing"
                    ? setCollecting({
                        ...collecting,
                        inputs: undefined,
                        state: "collecting",
                      })
                    : setCollecting(undefined)
                }
              />
            )}
            <TextXLarge bold>
              {switchEnum(mode, {
                steps: "Start Flow",
                free: "Start Flow from Idea",
                template: "Start Flow from Template",
              })}
            </TextXLarge>
          </HStack>

          <LocationSelect
            location={location}
            onChange={setLocation}
            allowed={["team"]}
          />
        </SpaceBetween>

        {!!collecting?.state && (
          <WorkflowCollectAndRun
            location={location}
            setLocation={setLocation}
            collecting={collecting}
            setCollecting={setCollecting}
            defaults={defaults}
            onClose={onClose}
          />
        )}

        {!collecting?.state && mode === "template" && (
          <WorkflowFromTemplate
            location={location}
            setLocation={setLocation}
            collecting={collecting}
            setCollecting={setCollecting}
            defaults={defaults}
            onClose={onClose}
          />
        )}

        {!collecting?.state && mode === "steps" && (
          <WorkflowFromSteps
            location={location}
            setLocation={setLocation}
            collecting={collecting}
            setCollecting={setCollecting}
            defaults={defaults}
            onClose={onClose}
          />
        )}

        {!collecting?.state && mode === "free" && (
          <WorkflowFromText
            location={location}
            setLocation={setLocation}
            collecting={collecting}
            setCollecting={setCollecting}
            defaults={defaults}
            onClose={onClose}
          />
        )}
      </SpaceBetween>
    </DialogSplit>
  );
};

export const WorkflowFromTemplate = ({
  location,
  setLocation,
  collecting,
  setCollecting,
  defaults,
  onClose,
}: SubProps) => {
  const me = useMe();
  const wId = useActiveWorkspaceId();
  const convert = useAiUseCase(freeTextToWorkflow);
  const [selection, setSelection] = useSelectableState();
  const [query, _setQuery] = useState<string>("");

  // Show workflow templates/processes from whole workspace when in private space
  const searchLocation = useMemo(
    () => (location === me.id ? wId : location),
    [location, wId]
  );
  const workflows = useSearch("workflow", searchLocation, {
    empty: true,
    templates: true,
  });
  const processes = useSearch("process", searchLocation, {
    empty: !collecting?.workflow,
  });

  const setQuery = useCallback(
    (q: Maybe<string>) => {
      const newVal = q || "";
      _setQuery(newVal);
      workflows.setQuery(newVal);
      processes.setQuery(newVal);
    },
    [_setQuery, workflows, processes]
  );

  const [loading, setLoading] = useState<Maybe<Workflow>>();
  const steps = useWorkflowSteps(loading);
  const finished = loading && !!steps?.length;

  const handleProcessSelected = useCallback(
    async (process: Process) => {
      const instructions = process.body;

      if (!instructions || isEmpty(instructions)) {
        showError("Add some steps for this workflow.");
        return;
      }

      // If we're in the private space, change the location to the root of the chosen process
      if (location === me.id) {
        setLocation(process.source.scope);
      }

      const [wf, ...steps] =
        (await convert.run({
          instructions,
          location,
        })) || [];

      if (!wf || !steps.length) {
        throw new Error("Workflow must be returned first.");
      }

      setCollecting({
        workflow: wf as Partial<Workflow>,
        steps: steps as Partial<WorkflowStep>[],
        inputs: undefined,
        state: "collecting",
      });
    },
    [location]
  );

  // Once workflow and steps are loaded, pass through to next step
  useEffect(() => {
    if (loading && finished) {
      // If we're in the private space, change the location to the root of the chosen workflow
      if (location === me.id) {
        setLocation(loading.source.scope);
      }

      setCollecting({
        workflow: loading,
        steps: steps,
        inputs: undefined,
        state: "collecting",
      });
    }
  }, [finished]);

  return (
    <>
      <div className={styles.softFill}>
        <VStack gap={20}>
          <SearchInput
            placeholder="Search templates..."
            search={query}
            setSearch={setQuery}
            autoFocus
          />

          <CollapsibleSection padded={false} title="Workflow Templates">
            <VStack fit="container">
              {map(
                workflows.results as Workflow[],
                (t) =>
                  // Only show enabled workflows
                  t.status?.id === "AVL" &&
                  render(getEngine("workflow").asListItem, {
                    key: t.id,
                    item: t,
                    onOpen: () => setLoading(t),
                  })
              )}
            </VStack>
          </CollapsibleSection>
          <CollapsibleSection padded={false} title="Follow a process">
            <VStack fit="container">
              {map(processes.results as Process[], (t) =>
                render(getEngine("process").asListItem, {
                  key: t.id,
                  item: t,
                  className: cx(
                    selection.selected.has(t.id) && styles.selected
                  ),
                  showLocation: false,
                  onOpen: () => {
                    setSelection(selectOnly(t.id));
                  },
                })
              )}
            </VStack>
          </CollapsibleSection>
        </VStack>
      </div>

      {!!selection.selected.size && (
        <HStack justify="flex-end" fit="container">
          <Button
            variant="primary"
            icon={Magic}
            loading={convert.loading}
            onClick={() =>
              when(
                find(processes.results as Process[], (f) =>
                  selection.selected.has(f.id)
                ),
                handleProcessSelected
              )
            }
          >
            Preview Flow
          </Button>
        </HStack>
      )}
    </>
  );
};

export const WorkflowFromSteps = ({ location, setCollecting }: SubProps) => {
  const [allInstructions, _setInstructions] = useState<RichText[]>([
    { html: "" },
  ]);
  const convert = useAiUseCase(freeTextToWorkflow);

  const setInstructions = useCallback(
    (inst: RichText, index: number) => {
      _setInstructions((all) => ensureHasEmpty(replace(all, index, inst)));
    },
    [_setInstructions]
  );

  const handleValidate = useCallback(async () => {
    const instructions = join(allInstructions) || { html: "" };

    if (isEmpty(instructions)) {
      showError("Add some steps for this workflow.");
      return;
    }

    const [wf, ...steps] =
      (await convert.run({
        instructions,
        location,
      })) || [];

    if (!wf || !steps.length) {
      throw new Error("Workflow must be returned first.");
    }

    setCollecting({
      workflow: wf as Partial<Workflow>,
      steps: steps as Partial<WorkflowStep>[],
      inputs: undefined,
      state: "collecting",
    });
  }, [allInstructions]);

  return (
    <>
      <div className={styles.softFill}>
        <VStack fit="container">
          {map(allInstructions, (instructions, index) => (
            <Fragment key={index}>
              <ColoredSection
                className={cx(
                  styles.textSection,
                  isEmpty(instructions) &&
                    !isFirst(index, allInstructions) &&
                    isLast(index, allInstructions) &&
                    styles.empty
                )}
              >
                <Field label="Do this...">
                  <TextBox
                    key="pre-built-info"
                    focus={isFirst(index, allInstructions)}
                    className={styles.textbox}
                    placeholder="Assign work, Prompt AI, Create a document..."
                    text={instructions}
                    onChanged={(i) => setInstructions(i, index)}
                    size="two-line"
                    controls={false}
                    // newLineSpace="small"
                  />
                </Field>
              </ColoredSection>
              {index !== allInstructions.length - 1 && <Divider label="then" />}
            </Fragment>
          ))}
        </VStack>
      </div>

      <SpaceBetween fit="container">
        <div></div>
        <Button
          variant="primary"
          icon={Magic}
          loading={convert.loading}
          onClick={handleValidate}
        >
          Preview Flow
        </Button>
      </SpaceBetween>
    </>
  );
};

export const WorkflowFromText = ({
  location,
  collecting,
  setCollecting,
  defaults,
  onClose,
}: SubProps) => {
  const [instructions, setInstructions] = useState<RichText>({ html: "" });
  const [plan, setPlan] = useState<Maybe<RichText>>();
  const convert = useAiUseCase(freeTextToWorkflow);
  const aiPlan = useStreamAIUseCase(freeFormToWorkflowPlan, (r) => setPlan(r));

  const handleValidate = useCallback(async (instructions: RichText) => {
    if (isEmpty(instructions)) {
      showError("Add some steps for this workflow.");
      return;
    }

    const [wf, ...steps] =
      (await convert.run({
        instructions,
        location,
      })) || [];

    if (!wf || !steps.length) {
      throw new Error("Workflow must be returned first.");
    }

    setCollecting({
      workflow: wf as Partial<Workflow>,
      steps: steps as Partial<WorkflowStep>[],
      inputs: undefined,
      state: "collecting",
    });
  }, []);

  return (
    <>
      <div className={styles.softFill}>
        <ColoredSection className={cx(styles.textSection)}>
          <VStack fit="container">
            <Field label="What do you need to get done?" padded>
              <DocumentEditor
                key="pre-built-info"
                autoFocus
                placeholder="Type your desired outcome..."
                content={instructions}
                onFocus={() => {
                  if (plan || aiPlan.loading) {
                    aiPlan.reset();
                    setPlan(undefined);
                  }
                }}
                onChanged={(i) =>
                  i.html !== instructions.html && setInstructions(i)
                }
                newLineSpace="small"
              />
            </Field>
          </VStack>

          {plan && (
            <>
              <Divider />

              <VStack fit="container">
                <Field label="Suggested work plan" padded>
                  {aiPlan.loading ? (
                    <ReadonlyDocument
                      key="output"
                      color="default"
                      content={plan}
                    />
                  ) : (
                    <DocumentEditor
                      key="output"
                      color="default"
                      content={plan}
                      onChanged={setPlan}
                    />
                  )}
                </Field>
              </VStack>
            </>
          )}
        </ColoredSection>
      </div>

      <HStack fit="container" justify="flex-end">
        <TextSmall subtle>Step {plan ? 2 : 1}/2</TextSmall>

        {!plan && (
          <Button
            variant="primary"
            icon={Magic}
            loading={aiPlan.loading}
            onClick={() => aiPlan.run({ outcome: instructions, location })}
          >
            Generate Plan
          </Button>
        )}

        {plan && (
          <Button
            variant="primary"
            icon={Magic}
            loading={convert.loading}
            onClick={() =>
              when(
                join([plan, { html: "<h2>Accepted plan:</h2>" }, instructions]),
                handleValidate
              )
            }
          >
            Preview Flow
          </Button>
        )}
      </HStack>
    </>
  );
};

const WorkflowCollectAndRun = ({
  location,
  collecting,
  setCollecting,
  defaults,
  onClose,
}: SubProps) => {
  const me = useMe();
  const parentId = useMemo(() => toParentScope(location), [location]);
  const parent = useLazyEntity(parentId);

  const runWorkflow = useStartWorkflow(parent, () => onClose?.());
  const createSteps = useCreateFromObject("workflow_step", location);
  const create = useCreateFromObject("workflow", location);
  const mutate = useMutate();
  const setCollect = useCallback(
    (dispatch: SetStateAction<PropertyValueRef[]>) =>
      setCollecting((c) =>
        !!c?.workflow && !!c?.steps
          ? {
              state: c.state,
              workflow: c.workflow,
              steps: c.steps,
              inputs:
                dispatch instanceof Function
                  ? dispatch(c.inputs || [])
                  : dispatch,
            }
          : undefined
      ),
    [collecting]
  );

  const handleCreateWorkflow = useCallback(
    async ({ workflow, steps, inputs, state }: StartWorkflowState) => {
      if (!create || !createSteps) {
        throw new Error("Not ready to create workflow.");
      }

      // Has inputs but not collected
      if (!!workflow?.inputs?.length && !inputs?.length) {
        setCollecting({
          workflow: workflow,
          steps,
          inputs: workflow.inputs,
          state,
        });
        return;
      }

      // Has not yet been reviewed
      if (state !== "reviewing") {
        setCollecting({ workflow, steps, inputs, state: "reviewing" });
        return;
      }

      if (!workflow || !workflow.id) {
        throw new Error("No workflow to create.");
      }

      // Creating from existing template
      if (workflow?.template) {
        if (!runWorkflow.ready) {
          throw new Error("Not ready to create workflow template.");
        }
        runWorkflow.start(workflow as Workflow, inputs, "foreground");
        return;
      }

      // Creating from AI output
      const [wf] = create([
        {
          ...workflow,
          status: { id: "RUN" },
          inputs,
        },
      ]);

      createSteps(
        map(steps, (s) =>
          merge(omit(s, "overrides", "options"), {
            options: {
              ...s.options,
              entity: when(safeAs<string>(s.options?.entity), healEntityType),
              // AI often stores the rich_text options in the PropertyValue format, unwrap.
              message: when(s.options?.message as Maybe<RichText>, (m) =>
                healPropertyValue({
                  type: "rich_text",
                  field: "options.message",
                  value: m,
                })
              )?.value.rich_text,
              prompt: when(
                s.options?.prompt as Maybe<RichText>,
                (m) =>
                  healPropertyValue({
                    type: "rich_text",
                    field: "options.prompt",
                    value: m,
                  })?.value.rich_text
              ),
            },
            overrides: map(s.overrides, healPropertyValue),
            location: toChildLocation(wf.source.scope, wf.id),
            refs: {
              workflow: [toRef(wf)],
            },
          } as Partial<WorkflowStep>)
        )
      );

      mutate(
        asUpdate(
          wf,
          asMutation({ field: "stamps.dirty", type: "stamp" }, toStamp(me))
        )
      );

      setCollecting(undefined);
      onClose?.();
    },
    [create, createSteps, runWorkflow.ready]
  );

  useEffect(() => {
    if (collecting) {
      handleCreateWorkflow(collecting);
    }
  }, [!!collecting?.workflow]);

  return (
    <>
      {collecting && collecting.state === "collecting" && (
        <>
          <div className={styles.softFill}>
            <VStack fit="container">
              <CollectWorkflowInputs
                values={collecting.inputs || []}
                setValues={setCollect}
                source={{ scope: location, type: "workflow" }}
              />
            </VStack>
          </div>
          <SpaceBetween fit="container">
            <div></div>
            <Button
              variant="primary"
              onClick={() => handleCreateWorkflow(collecting)}
            >
              Review
            </Button>
          </SpaceBetween>
        </>
      )}

      {collecting && collecting.state === "reviewing" && (
        <>
          <div className={styles.softFill}>
            <VStack>
              {map(collecting.steps, (s) =>
                render(getEngine("workflow_step").asListItem, {
                  key: s.id,
                  item: s as WorkflowStep,
                })
              )}
            </VStack>
          </div>

          <SpaceBetween fit="container">
            <div></div>
            <Button
              variant="primary"
              icon={Magic}
              loading={!!runWorkflow.starting}
              onClick={() => handleCreateWorkflow(collecting)}
            >
              Run
            </Button>
          </SpaceBetween>
        </>
      )}
    </>
  );
};

export const SmartStartWorkflowDialog = (props: Omit<Props, "defaults">) => {
  const location = useDefaultLocation();
  const defaults = useMemo(() => ({ location: location }), [location]);

  return <StartWorkflowDialog {...props} defaults={defaults} />;
};
