import { Campaign, EntityType, ID, Project } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useAllowedChildren } from "@state/properties";
import { useLazyEntity, useMarkAsSeen, useUpdateEntity } from "@state/generic";

import { cx } from "@utils/class-names";
import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { toChildLocation } from "@utils/scope";
import { useSyncPathnameID } from "@utils/url";

import { AddWorkButton } from "@ui/add-work-dialog";
import { useAppPageContext, usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { Centered } from "@ui/container";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { WorkflowSection } from "@ui/engine/workflow/section";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { SlidePaneManager } from "@ui/entity-pane-manager";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { PlusAlt, Swatch } from "@ui/icon";
import { PackageTag } from "@ui/package-label";
import { PageBodySection } from "@ui/page-body";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { LabelledPropertyValueList } from "@ui/property-value-tile";
import { ColorSelect } from "@ui/select/color";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { SubRouter, SubRoutesMenu } from "@ui/sub-router";
import { SummarySection } from "@ui/summary-section";
import { TemplateBanner } from "@ui/template-banner";
import { TextHighlight } from "@ui/text-highlight";
import { EntityUpdatesSection } from "@ui/updates-section";
import { ViewSubRoute } from "@ui/view-pane";
import { ViewRelationsSection } from "@ui/view-results-section";
import { WorkflowActions } from "@ui/workflow-action-button";

import AppPage from "./app-page";

import styles from "./project-page.module.css";

interface Props {
  projectId: string;
}

export const ProjectPage = ({ projectId }: Props) => {
  const pageId = useAppPageContext();

  const project = useLazyEntity<"project" | "campaign">(projectId);
  const childTypes = useAllowedChildren(project);

  const [page] = useRegisterPage(projectId, project);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(projectId, project?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(projectId, pageId);

  if (!project) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!project} title={project?.name}>
      <StackContainer>
        <SmartBreadcrumbSheet />

        <Sheet size="full" transparency="mid" interactable={false}>
          {!!project.template && <TemplateBanner />}

          <PageLayout>
            <ProjectSideNav entity={project} />

            <SubRouter
              id={projectId}
              router={(r) =>
                r.pathname === "/" ? (
                  <OverviewPage id={projectId} childTypes={childTypes} />
                ) : r.id ? (
                  <Main className={styles.main}>
                    <ViewSubRoute id={projectId} viewId={r.id} />
                  </Main>
                ) : (
                  <></>
                )
              }
            />
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

const OverviewPage = ({
  id,
  childTypes,
}: {
  id: ID;
  childTypes: EntityType[];
}) => {
  const pushTo = usePushTo();
  const project = useLazyEntity<"project" | "campaign">(id);

  if (!project) {
    return <></>;
  }

  return (
    <>
      <Main className={styles.main}>
        <EntityHeaderBar entity={project} padding="both" showTag={false} />

        <Centered stack="vertical" gap={50}>
          <SummarySection entityId={id} />

          {!project.template && (
            <HStack>
              <WorkflowActions entity={project} showAll={false} />
            </HStack>
          )}

          <EntityUpdatesSection
            id={id}
            entity={project}
            hideOnEmpty={true}
            mode="unseen"
          />

          <PageBodySection
            label="Brief"
            placeholder="Start briefing..."
            entity={{ id: id, entity: "project" }}
            body={project.body}
            showAll={false}
          />

          {project?.refs?.fromWorkflow && (
            <WorkflowSection items={project?.refs?.fromWorkflow} />
          )}

          {childTypes?.includes("page") && (
            <ViewRelationsSection
              parentId={project.id}
              childType="page"
              onOpen={pushTo}
            />
          )}

          {childTypes?.includes("outcome") && (
            <ViewRelationsSection
              parentId={project.id}
              childType="outcome"
              onOpen={pushTo}
            />
          )}

          {childTypes?.includes("deal") && (
            <ViewRelationsSection
              parentId={project.id}
              childType="deal"
              onOpen={pushTo}
            />
          )}

          {childTypes?.includes("content") && (
            <ViewRelationsSection
              parentId={project.id}
              childType="content"
              onOpen={pushTo}
            />
          )}

          {childTypes?.includes("task") && (
            <ViewRelationsSection
              parentId={project.id}
              childType="task"
              onOpen={pushTo}
            />
          )}
        </Centered>
      </Main>
      <SlidePaneManager id={id} entity={project} />
    </>
  );
};

interface HeaderProps {
  entity: Project | Campaign;
  className?: string;
}

export const ProjectSideNav = ({ entity, className }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(entity.id, pageId);

  if (!entity) {
    return <h1>Not found.</h1>;
  }

  return (
    <SideNav className={cx(styles.nav, className)}>
      <SpaceBetween direction="vertical">
        <VStack gap={30} fit="container">
          <PackageTag type={entity.source.type} scope={entity.source.scope} />

          <VStack gap={12} fit="container">
            <SpaceBetween>
              <MagicEmojiSelect
                key={`${entity.id}-emoji`}
                entity={entity}
                className={styles.icon}
                size="large"
                emoji={entity.icon || "💼"}
                onChange={(icon) =>
                  when(icon, (i) =>
                    mutate(asMutation({ field: "icon", type: "text" }, i))
                  )
                }
              />
              <ColorSelect
                color={entity.color}
                onChange={(c) =>
                  mutate([asMutation({ field: "color", type: "text" }, c)])
                }
              >
                <Button
                  subtle
                  size="small"
                  icon={<Swatch color={entity.color || "gray_5"} />}
                />
              </ColorSelect>
            </SpaceBetween>

            <HStack gap={8}>
              <TextHighlight color={entity.color}>
                <EditableHeading
                  key={`${entity.id}-heading`}
                  text={entity.name || ""}
                  size={entity.source.type === "project" ? "h2" : "h3"}
                  className={styles.heading}
                  onChange={(text) => {
                    when(text, (i) => {
                      mutate(asMutation({ field: "name", type: "text" }, i));
                    });
                  }}
                />
              </TextHighlight>
            </HStack>

            <AddWorkButton
              fit="content"
              icon={PlusAlt}
              defaults={{
                location: toChildLocation(entity.location, entity.id),
              }}
            >
              Add work
            </AddWorkButton>

            <Divider margin="on" />

            <SubRoutesMenu entity={entity} />

            <Divider margin="on" />

            <LabelledPropertyValueList
              stack="vertical"
              gap={10}
              entity={entity}
              onChange={mutate}
            />
          </VStack>
        </VStack>
      </SpaceBetween>
    </SideNav>
  );
};
