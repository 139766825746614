import { find, map } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";

import { SelectOption, WorkspaceConfig } from "@api";

import {
  addWorkspaceConfig,
  setActiveWorkspace,
  useLazyWorkspaces,
  WorkspaceSessionStateAtom,
} from "@state/workspace";

import { cx } from "@utils/class-names";
import { fallback } from "@utils/fn";
import { ifDo } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { SpaceBetween } from "@ui/flex";
import {
  AngleDoubleLeft,
  AngleDoubleRight,
  ArrowVertical,
  Icon,
  ImageIcon,
  PlusIcon,
  Traction,
  TractionLogo,
} from "@ui/icon";
import { Label } from "@ui/label";
import { Select } from "@ui/select/single-select";

import { WorkspaceCreateDialog } from "./workspace-create-dialog";

import styles from "./workspace-select.module.css";

interface Props {
  collapsed?: boolean;
  children?: ReactNode;
  onCollapse?: (collapsed: boolean) => void;
  iconRight?: boolean;
  className?: string;
}

const toIcon = (url: Maybe<string>) =>
  fallback(
    () => ifDo(url === "add", () => <PlusIcon />),
    () => when(url, (url) => <ImageIcon url={url} />),
    () => <Traction />
  );

export const WorkspaceSelect = ({
  children,
  iconRight,
  collapsed,
  onCollapse,
  className,
}: Props) => {
  const goTo = useGoTo();
  const [creating, setCreating] = useState(false);
  const setWorkspace = useSetRecoilState(WorkspaceSessionStateAtom);

  const { workspaces, active: activeID } = useLazyWorkspaces();

  const options = useMemo(
    () => [
      ...map(workspaces, (w) => ({
        id: w.workspace?.id,
        name: w.workspace?.name,
        url: w.workspace?.icon,
        icon: toIcon(w.workspace?.icon) || TractionLogo,
      })),
      { id: "add", name: "Create Workspace", url: "add" },
    ],
    [workspaces]
  );
  const active = useMemo(
    () => find(options, (o) => o.id === activeID),
    [activeID]
  );

  const setWorkspaceAndRedirect = (workspace: WorkspaceConfig) => {
    setCreating(false);
    setWorkspace(addWorkspaceConfig(workspace));
    goTo("/teams");
  };

  const onChanged = (o: Maybe<Partial<SelectOption>>) => {
    if (!o) {
      return;
    } else if (o.id === "add") {
      setCreating(true);
    } else if (o.id && o.id !== activeID) {
      setWorkspace(setActiveWorkspace(o.id));
      goTo("/teams");
    }
  };

  return (
    <Container className={cx(styles.workspaceContainer, className)}>
      {collapsed && (
        <Button fit="content" subtle onClick={() => onCollapse?.(false)}>
          <Icon icon={AngleDoubleRight} size="medium" />
        </Button>
      )}

      {!collapsed && (
        <SpaceBetween>
          <Select
            value={active}
            options={options}
            hideSelected={false}
            searchable={false}
            onChange={(o) => onChanged(o)}
          >
            <Button
              className={styles.workspaceButton}
              iconRight={iconRight !== false ? ArrowVertical : undefined}
              iconSize="xsmall"
              subtle
              size="regular"
              fit="container"
            >
              {children || (
                <Label className={styles.activeLabel} bold fit="content">
                  {active?.name}
                </Label>
              )}
            </Button>
          </Select>

          {onCollapse && (
            <Button
              subtle
              icon={AngleDoubleLeft}
              onClick={() => onCollapse(true)}
            />
          )}
        </SpaceBetween>
      )}

      {creating && (
        <WorkspaceCreateDialog
          defaults={{}}
          onSaved={setWorkspaceAndRedirect}
          onCancel={() => setCreating(false)}
        />
      )}
    </Container>
  );
};
