import { map } from "lodash";
import { useCallback } from "react";

import { Entity, Person, Team } from "@api";

import { ensureMany, OneOrMany } from "@utils/array";
import { asAppendMutation, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";

import { useMutate } from "@ui/mutate";

export const usePinTo = () => {
  const mutate = useMutate();

  const handlePin = useCallback(
    (to: Team | Person, items: OneOrMany<Entity>) =>
      mutate(
        asUpdate(
          to,
          asAppendMutation(
            { field: "refs.pins", type: "relations" },
            map(ensureMany(items), (i) => toRef(i))
          )
        )
      ),
    [mutate]
  );

  const handleUnpin = useCallback(
    (to: Team | Person, items: OneOrMany<Entity>) =>
      mutate(
        asUpdate(
          to,
          asAppendMutation(
            { field: "refs.pins", type: "relations" },
            map(ensureMany(items), (i) => toRef(i)),
            "remove"
          )
        )
      ),
    [mutate]
  );

  return { pin: handlePin, unpin: handleUnpin };
};
