import { useCallback } from "react";

import { ID } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";

import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";

import { Main, PageLayout } from "@ui/page-layout";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import ViewPane from "@ui/view-pane";

import AppPage from "./app-page";

import styles from "./my-work-page.module.css";

export const MyWorkPage = ({ viewId }: { viewId: Maybe<ID> }) => {
  const goTo = useGoTo();

  const setViewId = useCallback((id: string) => {
    goTo(["/boards", id]);
  }, []);

  const [page] = useRegisterPage();
  usePageUndoRedo(page.id);

  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <Main className={styles.main}>
              {viewId && (
                <ViewPane
                  viewId={viewId}
                  className={styles.viewPane}
                  onChangeView={(v) => setViewId(v.id)}
                  showOtherViews={false}
                  showViewsBar={false}
                />
              )}
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};
