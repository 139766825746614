import { flatMap, map } from "lodash";
import { useMemo } from "react";

import { toLabel, useLazyEntities } from "@state/generic";
import { useMe } from "@state/persons";
import { useLazyMyTeams } from "@state/teams";

import { useShowMore } from "@utils/hooks";
import { useGoTo } from "@utils/navigation";
import { uniqRefs } from "@utils/relation-ref";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { EllipsisH } from "@ui/icon";
import { RelationIcon } from "@ui/relation-label";
import { Text } from "@ui/text";

import { AppCommandsProps } from "./types";
import { useIsSearching } from "./utils";

export const PinnedCommands = ({}: AppCommandsProps) => {
  const me = useMe();
  const goTo = useGoTo();
  const myTeams = useLazyMyTeams();

  const isSearching = useIsSearching();
  const allRefs = useMemo(
    () =>
      uniqRefs([
        ...(me.refs?.pins || []),
        ...flatMap(myTeams, (t) => t.refs?.pins || []),
      ]),
    [me, myTeams]
  );

  const allPins = useLazyEntities(allRefs);

  const {
    visible: pins,
    hasMore,
    showMore,
  } = useShowMore(allPins || [], 5, isSearching);

  const Pins = useMemo(
    () =>
      map(pins, (f) => (
        <CommandItem
          key={f.id}
          value={`go to ${toLabel(f)} ${f.id}`}
          icon={<RelationIcon relation={f} />}
          onClick={() => goTo(f)}
        >
          {toLabel(f)}
        </CommandItem>
      )),
    [pins]
  );

  if (!pins?.length) {
    return <></>;
  }

  return (
    <CommandGroup label="Pinned">
      {Pins}

      {hasMore && (
        <CommandItem
          value="show more pinned"
          icon={EllipsisH}
          onSelectAction="none"
          onClick={() => showMore()}
        >
          <Text subtle>Show more pinned</Text>
        </CommandItem>
      )}
    </CommandGroup>
  );
};
