import { Dictionary, first, keys, map, reduce, toLower } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Entity, EntityType, Ref } from "@api";

import { useDuplicate, useLazyEntity, useNestedEntities } from "@state/generic";
import { useEntityLabels } from "@state/settings";

import { Fn } from "@utils/fn";
import { mask, maybeValues } from "@utils/object";
import { plural } from "@utils/string";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack, VStack } from "@ui/flex";
import { Check, SpinnerIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { Modal } from "@ui/modal";
import { Text, TextLarge } from "@ui/text";

interface Props {
  target: Ref;
  onComplete?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const DuplicateDialog = ({ target, onCancel, onComplete }: Props) => {
  const entity = useLazyEntity(target.id);
  const { children, loading } = useNestedEntities(entity, undefined, true);
  const duplicate = useDuplicate(entity?.source);
  const [opts, setDuplicate] = useState<Partial<Record<EntityType, boolean>>>(
    {}
  );
  const toLabel = useEntityLabels(entity?.source.scope);

  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );

  const handleSubmit = useCallback(() => {
    if (!entity) {
      return;
    }

    const neww = duplicate.run(
      entity,
      mask(children as Dictionary<Entity[]>, opts)
    );
    onComplete?.(first(neww) as Ref);
  }, [duplicate.run, entity, opts]);

  useEffect(() => {
    setDuplicate(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  if (!entity) {
    return <></>;
  }

  return (
    <Modal open={true} onOpenChanged={() => onCancel?.()}>
      <Container stack="vertical" padding="none" gap={20}>
        <VStack gap={4}>
          <TextLarge bold>Duplicate {entity.source.type}</TextLarge>
          <Text>
            Do you want to duplicate all work that lives within this{" "}
            {entity.source.type}?
          </Text>
        </VStack>

        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Menu>
              {loading && (
                <MenuGroup>
                  <Label icon={SpinnerIcon}>Collecting nested work...</Label>
                </MenuGroup>
              )}
              {!loading && (
                <MenuGroup>
                  <CheckMenuItem checked={true} disabled>
                    Duplicate 1 selected {toLower(toLabel(entity.source.type))}
                  </CheckMenuItem>
                  {!hasChildren && (
                    <MenuItem disabled icon={Check} text="No nested work" />
                  )}
                  {map(children, (values, type) => (
                    <CheckMenuItem
                      key={type}
                      checked={opts[type as EntityType] ?? false}
                      onChecked={() =>
                        setDuplicate((d) => ({
                          ...d,
                          [type]: !(opts[type as EntityType] ?? false),
                        }))
                      }
                    >
                      Duplicate {values?.length || 0} nested{" "}
                      {plural(type, values?.length || 0)}
                    </CheckMenuItem>
                  ))}
                </MenuGroup>
              )}
            </Menu>
          </Container>
        </FillSpace>

        <HStack gap={4} fit="container" justify="flex-end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>
            Duplicate
          </Button>
        </HStack>
      </Container>
    </Modal>
  );
};
