import { useMemo } from "react";

import { Team } from "@api";

import { useUpdateEntity } from "@state/generic";
import { usePinTo } from "@state/generic/effects/pinning";
import { useMe } from "@state/persons";

import { respectHandled } from "@utils/event";
import { when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { toNotionUrl } from "@utils/notion";
import { toPropertyValueRef } from "@utils/property-refs";
import { containsRef, includesRef } from "@utils/relation-ref";
import { toSlackUrl } from "@utils/slack";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  Cog,
  Icon,
  Notion,
  Pin,
  PinSlash,
  SlackColor,
  TeamIcon,
} from "@ui/icon";
import { ColorSelect } from "@ui/select/color";
import { Heading } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import styles from "./team-header.module.css";

interface Props {
  team: Team;
}

export const TeamHeader = ({ team }: Props) => {
  const me = useMe();
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const mutate = useUpdateEntity(team.id);
  const { pin, unpin } = usePinTo();
  const amInTeam = useMemo(
    () => containsRef(me.teams, team),
    [me.teams, team?.id]
  );
  const isPinned = useMemo(
    () => containsRef(me.refs?.pins, team),
    [me.teams, team?.id]
  );

  if (!team) {
    return <h1>Not found.</h1>;
  }

  return (
    <Container padding="none" inset="top">
      <VStack gap={40}>
        <VStack gap={6} className={styles.teamName}>
          <SpaceBetween>
            <HStack>
              <ColorSelect
                color="green"
                onChange={(color) =>
                  mutate({
                    field: "color",
                    type: "text",
                    value: { text: color },
                  })
                }
              >
                {team && <Icon icon={<TeamIcon team={team} />} size="large" />}
              </ColorSelect>
              <Heading bold>{team.name || "Team Home"}</Heading>
            </HStack>

            <HStack gap={4}>
              {amInTeam && !isPinned && (
                <Tooltip text="Pin to sidebar" side="bottom">
                  <Button icon={Pin} onClick={() => pin(me, team)} />
                </Tooltip>
              )}
              {amInTeam && isPinned && (
                <Tooltip text="Unpin from sidebar" side="bottom">
                  <Button
                    variant="primary"
                    subtle
                    icon={Pin}
                    onClick={() => unpin(me, team)}
                  />
                </Tooltip>
              )}

              <Button
                size="small"
                icon={Cog}
                onClick={() => pushTo("/settings/data")}
              >
                Settings
              </Button>
              {team?.notionId && (
                <Button
                  icon={Notion}
                  subtle
                  onClick={respectHandled(() =>
                    when(team?.notionId, (n) => goTo(toNotionUrl(n)))
                  )}
                />
              )}
              {when(
                team &&
                  toPropertyValueRef(team, {
                    field: "settings.channel",
                    type: "text",
                  }),
                ({ value }) => (
                  <Button
                    icon={SlackColor}
                    subtle
                    onClick={respectHandled(() =>
                      when(value.text, (n) => goTo(toSlackUrl(n)))
                    )}
                  />
                )
              )}
            </HStack>
          </SpaceBetween>
        </VStack>
      </VStack>
    </Container>
  );
};
